<script>
  import debounce from 'lodash/debounce';
  import { format } from 'svelte-i18n';

  import { SparkChart } from '@client/components/charts';

  import { ReportsService } from '@client/services/reports';

  import { generateDatesInRange } from '@client/utils/dates';

  export let row = {};

  let options = {};
  let chartData = {
    datasets: []
  };

  const fetchReportData = debounce(async () => {
    const link = row.link;
    const views = await ReportsService.getViews({ link, days: 30 });

    let data = [];
    let yAxisMax = 0;

    const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const endDate = new Date();
    const labels = generateDatesInRange(startDate, endDate);

    if (views.length) {
      const dates = views.reduce((acc, view) => {
        acc[view._id] = view;
        return acc;
      }, {});

      labels.forEach((day) => {
        if (dates[day]) {
          data.push(dates[day].total);

          yAxisMax = Math.max(yAxisMax, dates[day].total);
        } else {
          data.push(0);
        }
      });
    } else {
      data = Array(30).fill(0);
    }

    chartData = {
      labels,
      datasets: [
        {
          label: $format('label.VIEWS'),
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, '#f3e3ff');
            gradient.addColorStop(1, '#e3e3ff');

            return gradient;
          },
          borderColor: 'rgb(0, 135, 255)',
          pointColor: 'rgb(0, 135, 255)',
          fill: true,
          tension: 0.3,
          data
        }
      ]
    };

    options = {
      scales: {
        x: {
          type: 'time',
          time: {
            parser: 'yyyy-MM-dd',
            displayFormats: {
              day: 'M/d',
              month: 'MMM'
            },
            tooltipFormat: 'MMMM d, yyyy',
            unit: 'day',
            round: 'day'
          }
        },
        y: {
          max: yAxisMax + 1
        }
      }
    };
  }, 300);

  $: fetchReportData(row.link);
</script>

<div class="spark-chart">
  <SparkChart bind:data={chartData} bind:options height="50px" />
</div>

<style>
  .spark-chart {
    max-width: 100px;
  }
</style>
