<script>
  import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Tooltip } from 'sveltestrap';
  import { format } from 'svelte-i18n';

  import { QueryParams } from '@client/utils/query-params';

  export let disabled = false;
  export let items = [];
  export let label = '';
  export let menuHeight = 'auto';
  export let param = null;
  export let search = false;
  export let selectedValues = '';
  export let tooltipLabel = '';
  export let onClick = () => {};

  let isOpen = false;
  let selected = selectedValues.split(',').filter(Boolean);
  let selectedLabels = [];
  let filtered = [...items];

  const id = Math.floor(Math.random() * 10000);

  const labels = items.reduce((obj, config) => {
    if (!obj[config.value]) {
      obj[config.value] = config.label;
    }

    return obj;
  }, {});

  const onClickHandler = (e, item) => {
    if (item.value === 'all') {
      const all = selected.length === items.length;
      selected = all ? [] : items.map(({ value }) => value);
    } else {
      if (selected.includes(item.value)) {
        selected.splice(selected.indexOf(items.value), 1);
        selectedLabels.splice(selectedLabels.indexOf(labels[items.value]), 1);
      } else {
        selected.push(item.value);
        selectedLabels.push(labels[item.value]);
      }
    }

    selected = selected;
    selectedLabels = selectedLabels;

    selectedValues = selected.join();

    onClick(e, item.value, items);
  };

  const onSearch = (e) => {
    const value = e.target.value;

    filtered = items.filter(({ label }) => label.toLowerCase().includes(value));
  };

  const onFocus = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  const updateQueryParams = () => {
    if (selectedValues.length) {
      QueryParams.set(param, selectedValues);
    } else {
      QueryParams.delete(param);
    }

    selected = selectedValues.split(',').filter(Boolean);
    selectedLabels = selected.map((value) => labels[value]);
  };

  $: param && updateQueryParams(selectedValues);
  $: filtered = [...items];
</script>

<Dropdown {isOpen} autoClose="outside" toggle={() => (isOpen = !isOpen)}>
  <DropdownToggle tag="div">
    <button id="filter-{id}" class="filter-button" class:active={selected.length} class:disabled {disabled}>
      {label} <span>{selected.length}</span>
    </button>
    {#if selected.length && !isOpen}
      <Tooltip target="filter-{id}" placement="right">
        {tooltipLabel}
        {selectedLabels.map((label) => $format(label)).join(', ')}
      </Tooltip>
    {/if}
  </DropdownToggle>
  <DropdownMenu style="max-height: {menuHeight}; overflow: auto;" class={search ? 'sticky-search' : ''}>
    {#if search}
      <DropdownItem on:click={onFocus} class="search-item-container">
        <div class="search-item">
          <input type="text" on:click|preventDefault on:input={onSearch} placeholder="Search" />
        </div>
      </DropdownItem>
    {/if}
    <DropdownItem on:click={(e) => onClickHandler(e, { value: 'all' })}>
      {#if selected.length === items.length}
        {$format('label.UNSELECT_ALL')}
      {:else}
        {$format('label.SELECT_ALL')}
      {/if}
    </DropdownItem>
    {#each filtered as item}
      <DropdownItem on:click={(e) => onClickHandler(e, item)}>
        <div class="filter-item">
          <i class={selected.includes(item.value) ? 'links-icon-check-square' : 'links-icon-square'} />
          <span class="filter-label">
            <slot name="label" {item}>
              {$format(item.label)}
            </slot>
          </span>
        </div>
      </DropdownItem>
    {/each}
  </DropdownMenu>
</Dropdown>

<style lang="scss" src="./multiselect.scss"></style>
