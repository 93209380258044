<script>
  import { getContext } from 'svelte';
  import { format } from 'svelte-i18n';
  import { slide } from 'svelte/transition';

  import { Button } from '@client/components/button';
  import { Input } from '@client/components/input';
  import { Loader } from '@client/components/loader';
  import { Switch } from '@client/components/switch';

  import { LinksService } from '@client/services/links';

  import { userStore } from '@client/stores/user';

  const { prefix } = getContext('config');

  export let uniqueLinkName = false;
  export let currentUser = $userStore;
  export let onSave = () => {};
  export let nextStep = () => {};

  export let link = {
    title: '',
    link: '',
    private: false,
    source: ''
  };

  let submitButtonRef = null;
  let titleRef = null;
  let redirectRef = null;
  let linkRef = null;
  let linkSourceIsValid = false;
  let timer = null;
  let isSaving = false;

  const debounce = (fn, delay = 100) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  };

  const isLinkUnique = async (e) => {
    onKeyUp(e);

    link.link = link.link.toLowerCase().replace(' ', '-');

    const response = await LinksService.get(link.link);
    uniqueLinkName = response === true;
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();

      if (next < 3) {
        nextField();
      }
    }
  };

  const onCreate = async () => {
    const { user } = currentUser;

    isSaving = true;

    link.link = link.link.toLowerCase().replace(' ', '-');
    link.createdBy = currentUser;

    link.updatedBy = user;

    const response = await LinksService.create(link);

    console.log(response);

    onSave(true);

    linkSourceIsValid = false;

    nextStep();

    isSaving = false;
  };

  let next = 0;

  const nextField = () => {
    next = next + 1;

    if (next === 1) {
      setTimeout(() => redirectRef.focus(), 300);
    }

    if (next === 2) {
      setTimeout(() => linkRef.focus(), 300);
    }

    if (next === 3) {
      setTimeout(() => submitButtonRef.focus(), 150);
    }
  };

  setTimeout(() => titleRef.focus(), 300);

  $: isFormValid = link.title.length && link.source.length && link.link.length && linkSourceIsValid && uniqueLinkName;
</script>

<div class="wizard-page create">
  <h1>{$format('label.TELL_US_ABOUT_YOUR_LINK')}</h1>
  <img src="/images/create.svg" alt="Create new link" />

  <Loader backdrop show={isSaving} />

  <div class="create-form">
    <div class="field" in:slide|local>
      <label for="title">{$format('label.ENTER_DESCRIPTIVE_TITLE')}</label>
      <div class="link-input">
        <Input
          bind:ref={titleRef}
          type="text"
          id="title"
          class="fill"
          placeholder="Team Drive"
          bind:value={link.title}
          on:keyup={onKeyUp}
        />
        {#if link.title?.length}
          <div class="indicator">
            {#if link.title?.length > 2}
              <i class="links-icon-check" />
            {:else}
              <i class="links-icon-block" />
            {/if}
          </div>
        {/if}
      </div>

      {#if next === 0}
        <button class="next-button" disabled={!link.title.length} on:click={nextField}>{$format('label.NEXT')}</button>
      {/if}
    </div>

    {#if next > 0}
      <div class="field" in:slide|local>
        <label for="source">{$format('label.ENTER_REDIRECT_URL')}</label>
        <div class="link-input">
          <Input
            bind:ref={redirectRef}
            type="text"
            id="source"
            class="fill"
            placeholder="https://drive.google.com"
            bind:value={link.source}
            on:keyup={onKeyUp}
            bind:valid={linkSourceIsValid}
            pattern="^(http|https)://(.*)"
          />
          {#if link.source?.length}
            <div class="indicator">
              {#if linkSourceIsValid}
                <i class="links-icon-check" />
              {:else}
                <i class="links-icon-block" />
              {/if}
            </div>
          {/if}
        </div>

        {#if next === 1}
          <button class="next-button" disabled={!link.source.length} on:click={nextField}
            >{$format('label.NEXT')}</button
          >
        {/if}
      </div>
    {/if}

    {#if next > 1}
      <div class="field" in:slide|local>
        <label for="link">{$format('label.ENTER_MEMORABLE_LINK')}</label>
        <div class="link-input">
          <div class="link-input-prepend">{prefix}/</div>
          <Input
            bind:ref={linkRef}
            type="text"
            id="link"
            class="fill"
            placeholder="drive"
            bind:value={link.link}
            on:keyup={(e) => debounce(() => isLinkUnique(e))}
          />
          {#if link.link?.length >= 2}
            <div class="indicator">
              {#if uniqueLinkName}
                <i class="links-icon-check" />
              {:else}
                <i class="links-icon-block" />
              {/if}
            </div>
          {/if}
        </div>

        {#if next === 2}
          <button class="next-button" disabled={!link.link.length} on:click={nextField}>{$format('label.NEXT')}</button>
        {/if}
      </div>
    {/if}

    {#if next > 2}
      <div class="field" in:slide|local>
        <label for="link">{$format('label.MAKE_LINK_PRIVATE')}</label>
        <Switch bind:checked={link.private} />
        {#if link.private}
          <small>{$format('label.NOT_PUBLICLY_LISTED')}</small>
        {/if}
      </div>
    {/if}

    {#if next > 2}
      <Button className="block" bind:ref={submitButtonRef} on:click={onCreate} disabled={!isFormValid}>
        Create my link
      </Button>
    {/if}
  </div>
</div>

<style lang="scss" src="../getting-started.scss"></style>
