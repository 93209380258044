import { writable } from 'svelte/store';
import { LocalStorage } from '@client/utils/local-storage';

const linksSettings = LocalStorage.get('links:settings');
const usersSettings = LocalStorage.get('users:settings');
const tagsSettings = LocalStorage.get('tags:settings');

if (!linksSettings) {
  LocalStorage.create('links:settings', { params: {}, filters: {} });
}

if (!usersSettings) {
  LocalStorage.create('users:settings', { params: {}, filters: {} });
}

if (!tagsSettings) {
  LocalStorage.create('tags:settings', { params: {}, filters: {} });
}

export const linksSettingsStore = writable(linksSettings || { params: {}, filters: {} });

linksSettingsStore.subscribe((store) => LocalStorage.update('links:settings', store));

export const usersSettingsStore = writable(usersSettings || { params: {}, filters: {} });

usersSettingsStore.subscribe((store) => LocalStorage.update('users:settings', store));

export const tagsSettingsStore = writable(tagsSettings || { params: {}, filters: {} });

tagsSettingsStore.subscribe((store) => LocalStorage.update('tags:settings', store));
