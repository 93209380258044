<script>
  import { format } from 'date-fns';

  import { relativeTimestamp } from '@client/utils/dates';

  export let row = {};
  export let tags;

  let domain = '';

  const extractDomain = (url) => {
    try {
      let parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (e) {
      console.error('Invalid URL:', e);
      return null;
    }
  };

  const updateSrc = function () {
    this.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  };

  $: domain = extractDomain(row.originalUrl);
  $: expiration = row?.expiry;
  $: hasExpired = expiration && new Date(expiration) < new Date();
  $: expirationStatus = expiration && (hasExpired ? 'Expired' : `Expires ${relativeTimestamp(expiration)}`);
</script>

<div class="title">
  {row.title}
</div>

<div class="shorturl">
  <a href={row.shortUrl} target="_blank">{row.shortUrl}</a>
  {#if expiration}
    <span class="expired-badge" class:expired={hasExpired}>{expirationStatus}</span>
  {/if}
</div>

<div class="source">
  <div class="img">
    <img
      src="https://logo.clearbit.com/{domain}"
      on:load={updateSrc}
      referrerpolicy="no-referrer"
      alt={domain}
      style="background: url(https://logo.clearbit.com/{domain}) no-repeat center center / contain;"
    />
  </div>
  <a href={row.originalUrl} target="_blank">{row.originalUrl}</a>
</div>

<small>
  {#if row.lastViewed}
    Last visited {relativeTimestamp(row.lastViewed)}
  {/if}

  {#if row.lastViewed && expiration}
    -
  {/if}

  {#if expiration}
    {hasExpired ? 'Expired' : 'Expires'} on {format(new Date(expiration), 'MMM d, yyyy')}
  {/if}
</small>

{#if row.tags}
  <div class="tags">
    {#each row.tags as tag}
      <span class="tag" style="background-color: {tags[tag].bg}; color: {tags[tag].color}">{tags[tag].label}</span>
    {/each}
  </div>
{/if}

<style lang="scss">
  .title {
    color: var(--color-gray-800);
  }

  .expired-badge {
    background-color: var(--color-orange-100);
    border-radius: var(--border-radius-base);
    color: var(--color-red-400);
    font-size: var(--font-size-small);
    padding: var(--padding-small) var(--padding-base);
    white-space: nowrap;

    &.expired {
      background-color: var(--color-red-100);
    }
  }

  .shorturl {
    align-items: center;
    display: flex;
    color: var(--color-navy);
    flex-wrap: wrap;
    gap: 8px;
    margin: var(--margin-xsmall) 0;
  }

  .source {
    align-items: center;
    display: flex;
    font-size: var(--font-size-medium);
    gap: 8px;
    margin: 6px 0;
    word-break: break-all;

    .img {
      border-radius: 100%;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
      margin: 0;
      min-width: 20px;
      height: 20px;
      overflow: hidden;
      width: 20px;

      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }

    + .tags {
      margin-top: 8px;
    }

    a {
      color: var(--color-gray-700);
      display: -webkit-box;
      overflow: hidden;
      word-break: break-all;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  small {
    color: var(--color-gray-600);
    display: block;
  }

  .tags {
    display: flex;
    gap: 8px;
    margin: 6px 0;
  }

  .tag {
    background-color: var(--color-gray-400);
    border-radius: var(--border-radius-base);
    color: var(--color-gray-800);
    font-size: var(--font-size-small);
    padding: 4px 8px;
  }

  @media only screen and (max-width: 675px) {
    .title,
    .shorturl {
      font-size: var(--font-size-medium);
    }
  }
</style>
