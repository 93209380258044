<script>
  import Select from 'svelte-select';
</script>

<span class="styled">
  <Select
    {...$$props}
    placeholderAlwaysShow
    on:blur
    on:change
    on:clear
    on:error
    on:filter
    on:focus
    on:input
    on:loaded
    on:remove
  >
    <slot name="item" slot="item" let:item {item}>
      {item.label}
    </slot>

    <slot name="selection" slot="selection" let:selection {selection}>
      {selection.label}
    </slot>

    <slot name="empty" slot="empty">
      <div class="empty">There are more options available.</div>
    </slot>
  </Select>
</span>

<style lang="scss" src="./select.scss"></style>
