<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';
  import { link } from 'svelte-navigator';

  import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'sveltestrap';

  import { addDays, subDays, format as formatDate } from 'date-fns';
  import { DatePicker } from '@client/components/datepicker';

  import { Loader } from '@client/components/loader';
  import { Select } from '@client/components/select';

  import { ReportsService } from '@client/services/reports';
  import { LinksService } from '@client/services/links';

  import { abbreviateNumber } from '@common/utils/numbers';

  import { COLOR_PALETTE } from '@client/enums';
  import { Chart } from './charts';
  import { Chart as Charts } from '@client/components/charts';

  import { generateDatesInRange } from '@client/utils/dates';

  const today = new Date();

  let isOpen = false;
  let loading = false;

  let colorMap = {};
  let colorIndex = 0;

  let dateFormat = 'MMM d, yyyy';

  let startDate = subDays(today, 29);
  let endDate = today;

  let formattedStartDate = '';
  let formattedEndDate = '';

  let links = [];
  let originalLinks = [];
  let selectedLinks = [];
  let maxSelectedLinks = 5;
  let selectedField = '';
  let selectedItemField = '';

  let os = { data: [], loading: false };
  let browsers = { data: [], loading: false };
  let countries = { data: [], loading: false };
  let cities = { data: [], loading: false };
  let regions = { data: [], loading: false };
  let referrers = { data: [], loading: false };
  let paths = { data: [], loading: false };
  let broken = { data: [], loading: false };
  let devices = { data: [], loading: false };
  let popular = { data: [], loading: false };
  let trending = { data: [], loading: false };
  let tags = { data: [], loading: false };
  let topusers = { data: [], loading: false };
  let toplinks = { data: [], loading: false };
  let time = { data: [], loading: false };

  let kpis = {
    heading: {
      views: {
        title: $format('label.VIEWS'),
        description: $format('label.TOOLTIP.KPI_VIEWS')
      },
      users: {
        title: $format('label.USERS'),
        description: $format('label.TOOLTIP.KPI_USERS')
      },
      app: {
        title: 'App',
        description: $format('label.TOOLTIP.KPI_SOURCE_APP')
      },
      omnibox: {
        title: 'Omnibox',
        description: $format('label.TOOLTIP.KPI_SOURCE_OMNIBOX')
      },
      search: {
        title: 'Search',
        description: $format('label.TOOLTIP.KPI_SOURCE_SEARCH')
      },
      'search-widget': {
        title: 'Search Widget',
        description: $format('label.TOOLTIP.KPI_SOURCE_SEARCH_WIDGET')
      },
      'text-expander': {
        title: 'Text Expander',
        description: $format('label.TOOLTIP.KPI_SOURCE_TEXT_EXPANDER')
      }
    },
    data: [],
    loading: false
  };

  let chartData = {};
  let users = [];
  let noViewSource = false;

  let noViewHistory = false;
  let viewsConfig = {};
  let viewsOptions = {};

  let typeFields = [
    {
      label: $format('label.SHORTCUTS'),
      value: 'redirect'
    },
    {
      label: $format('label.SNIPPETS'),
      value: 'snippet'
    },
    {
      label: $format('label.TEMPLATES'),
      value: 'template'
    }
  ];

  selectedField = typeFields[0];

  selectedItemField = selectedField.value;

  const assignColorsToLinks = (linkItems = links) => {
    linkItems.forEach((url) => {
      if (!colorMap[url]) {
        colorMap[url] = {
          backgroundColor: COLOR_PALETTE[colorIndex % COLOR_PALETTE.length],
          hoverBackgroundColor: COLOR_PALETTE[colorIndex % COLOR_PALETTE.length].replace('.99', '0.8')
        };

        colorIndex++;
      }
    });

    colorMap = { ...colorMap };

    return colorMap;
  };

  const selectField = async (field) => {
    typeFields = typeFields.map((item) => {
      item.selected = field.value === item.value;

      return item;
    });

    selectedField = field;
    selectedItemField = field.value;

    noViewHistory = false;
    viewsConfig = {};
    viewsOptions = {};
    selectedLinks = [];

    await getLinks();
    onContextChange();
  };

  const getViewsBySource = async () => {
    noViewSource = false;

    const views = await ReportsService.getViewReport({
      report: 'source',
      type: selectedItemField, //links.length ? selectedItemField : null,
      links,
      startDate,
      endDate
    });

    const labels = [];
    const data = [];

    if (!views.length) {
      noViewSource = true;
      return;
    }

    views.forEach((view) => {
      const source = view._id.source ? view._id.source : $format('label.UNKNOWN');
      labels.push(source[0].toUpperCase() + source.slice(1));
      data.push(view.total);
    });

    chartData = {
      labels,
      datasets: [
        {
          data,
          label: $format('label.TOTAL'),
          backgroundColor: [...COLOR_PALETTE],
          hoverBackgroundColor: [...COLOR_PALETTE.map((item) => item.replace('.99', '0.8'))]
        }
      ]
    };
  };

  const getTopUsers = async () => {
    users = await ReportsService.getUserReport({ report: 'top', limit: 5 });
  };

  const getViewEngagement = async () => {
    noViewHistory = false;

    const views = await ReportsService.getViewReport({
      report: 'views',
      type: selectedItemField, //links.length ? selectedItemField : null,
      links,
      startDate,
      endDate
    });

    if (!views.length) {
      noViewHistory = true;
      return;
    }

    const linksItems = [...new Set(views.map(({ z }) => z))];
    const colors = assignColorsToLinks(linksItems);
    const days = generateDatesInRange(new Date(startDate), addDays(new Date(endDate), 1));

    const datasets = linksItems.map((link) => {
      const data = days.map((day) => {
        const viewForDay = views.find((view) => view.x === day && view.z === link);
        return viewForDay ? viewForDay.y : 0;
      });

      return {
        label: link,
        data,
        fill: 'origin',
        backgroundColor: colors[link].backgroundColor,
        hoverBackgroundColor: colors[link].hoverBackgroundColor,
        barThickness: 6
      };
    });

    viewsConfig = {
      labels: days,
      datasets
    };

    viewsOptions = {
      hideTooltipZeros: true,
      sortByValues: true,
      elements: {
        point: {
          radius: 0
        }
      },
      scales: {
        x: {
          type: 'time',
          position: 'bottom',
          stacked: true,
          time: {
            parser: 'yyyy-MM-dd',
            displayFormats: {
              day: 'M/d',
              month: 'MMM'
            },
            tooltipFormat: 'MMMM d, yyyy',
            unit: 'day',
            round: 'day'
          },
          grid: {
            display: true
          },
          ticks: {
            maxRotation: 0,
            stepSize: 2
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true
          },
          ticks: {
            display: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value.toLocaleString();
              }
            }
          }
        }
      }
    };
  };

  const toggleDateRangePicker = () => (isOpen = !isOpen);

  const onContextChange = () => {
    links = (selectedLinks || []).map(({ value }) => value);
    onDateChange();
  };

  const formattedDate = (dateString) => (dateString && formatDate(new Date(dateString), dateFormat)) || '';

  const onDateChange = () => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    colorMap = {};
    colorIndex = 0;
    assignColorsToLinks();
    getViewEngagement();
    getViewsBySource();
    getReports();
  };

  const getReportData = async (report, config) => {
    config.loading = true;

    const result = await ReportsService.getLinkReport({
      report,
      startDate,
      endDate,
      links,
      type: selectedItemField //links.length ? selectedItemField : null,
    });

    config.loading = false;

    return result;
  };

  const getViewReportData = async (report, params, config) => {
    config.loading = true;

    const result = await ReportsService.getViewReport({
      report,
      type: selectedItemField, //links.length ? selectedItemField : null,
      ...params
    });

    config.loading = false;

    return result;
  };

  const getReport = async (report) => {
    if (report === 'kpis') {
      const [config] = await getReportData(report, kpis);
      const sources = config.viewSources
        .map((item) => ({
          label: item.source,
          total: item.total,
          change: item.change
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

      const data = [
        {
          label: 'views',
          total: config.totalViews,
          change: config.totalViewsChange
        },
        {
          label: 'users',
          total: config.totalUniqueUsers,
          change: config.totalUniqueUsersChange
        },
        ...sources
      ];

      kpis.data = data;
    }

    if (report === 'trending') {
      const data = await getViewReportData(report, { type: selectedItemField, days: 90, limit: 3 }, trending);
      trending.data = data.sort((a, b) => b.total - a.total || a.date - b.date);
    }

    if (report === 'popular') {
      const data = await getViewReportData(report, { type: selectedItemField, days: 90, limit: 3 }, popular);
      popular.data = data.sort((a, b) => b.total - a.total || a.date - b.date);
    }

    if (report === 'os') {
      os.data = await getReportData(report, os);
    }

    if (report === 'browsers') {
      browsers.data = await getReportData(report, browsers);
    }

    if (report === 'countries') {
      countries.data = await getReportData(report, countries);
    }

    if (report === 'cities') {
      cities.data = await getReportData(report, cities);
    }

    if (report === 'regions') {
      regions.data = await getReportData(report, regions);
    }

    if (report === 'referrers') {
      referrers.data = await getReportData(report, referrers);
    }

    if (report === 'paths') {
      paths.data = await getReportData(report, paths);
    }

    if (report === 'broken') {
      broken.data = await getReportData(report, broken);
    }

    if (report === 'devices') {
      devices.data = await getReportData(report, devices);
    }

    if (report === 'tags') {
      tags.data = await getReportData(report, tags);
    }

    if (report === 'users') {
      topusers.data = await getReportData(report, topusers);
    }

    if (report === 'links') {
      toplinks.data = await getReportData(report, toplinks);
    }

    if (report === 'time') {
      time.data = await getReportData(report, time);
    }
  };

  const getReports = async () => {
    const reports = [
      'kpis',
      'os',
      'browsers',
      'countries',
      'cities',
      'regions',
      'referrers',
      'paths',
      'broken',
      'devices',
      'trending',
      'popular',
      'tags',
      'users',
      'links',
      'time'
    ];

    await Promise.all(reports.map(getReport));
  };

  getReports();

  const getLinks = async () => {
    const { records } = await LinksService.getAll({ type: selectedItemField, field: 'link', order: 1, perpage: 1000 });

    originalLinks = records.map(({ link, ...rest }) => ({
      ...rest,
      label: link,
      value: link
    }));
  };

  onMount(async () => {
    await getLinks();

    COLOR_PALETTE.forEach((m, index) => {
      document.documentElement.style.setProperty(`--dataset-color-${index}`, COLOR_PALETTE[index]);
    });
  });

  $: formattedStartDate = formattedDate(startDate);
  $: formattedEndDate = formattedDate(endDate);
  $: linkItems = maxSelectedLinks === links.length ? [] : originalLinks;

  getTopUsers();
  getViewsBySource();
  getViewEngagement();
</script>

<div class="page insights">
  <Loader backdrop show={loading} />
  <div class="page-header">
    <div class="row">
      <div class="col-md-12">
        <h1>{$format('label.INSIGHTS_DASHBOARD')}</h1>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="dashboard">
      <div class="left-column">
        <div class="context-bar">
          <div class="comparison-select">
            <Select
              bind:items={linkItems}
              bind:value={selectedLinks}
              on:change={onContextChange}
              on:clear={onContextChange}
              placeholder={links.length === maxSelectedLinks
                ? $format('label.PLACEHOLDER_CONTEXT_MAX_SELECTIONS')
                : $format('label.PLACEHOLDER_CONTEXT_CHOOSE', { values: { type: selectedField.label.toLowerCase() } })}
              clearable={false}
              searchable
              multiple
            >
              <svelte:fragment slot="item" let:item>
                <div class="item">
                  <div class="label">{item.value}</div>
                  <div class="value">{item.title}</div>
                </div>
              </svelte:fragment>
            </Select>
            <div class="category-dropdown">
              <Dropdown class="field-dropdown">
                <DropdownToggle tag="div" tabindex="0" caret>{selectedField?.label || ''}</DropdownToggle>
                <DropdownMenu>
                  {#each typeFields as field}
                    <DropdownItem active={field.value === selectedField?.value} on:click={() => selectField(field)}>
                      {field.label}
                    </DropdownItem>
                  {/each}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div class="date-filter">
            <DatePicker bind:isOpen bind:startDate bind:endDate isRange {onDateChange} showPresets align="right">
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div class="date-field" on:click={toggleDateRangePicker} class:open={isOpen} role="button" tabindex="0">
                <i class="icon-calendar" />
                <div class="date">
                  {formattedStartDate} - {formattedEndDate}
                </div>
              </div>
            </DatePicker>
          </div>
        </div>

        {#if kpis.data.length}
          <div class="metrics-summary-wrap">
            <div class="metrics-summary">
              {#each kpis.data as kpi}
                <div class="metric">
                  <div class="metric-label">
                    <span>{kpis.heading[kpi.label].title}</span>
                    <i class="links-icon-help-circle" id="tooltip-{kpi.label}" />
                    <Tooltip target="tooltip-{kpi.label}" placement="top">
                      <b>{kpis.heading[kpi.label].title}</b>
                      <div>{kpis.heading[kpi.label].description}</div>
                    </Tooltip>
                  </div>
                  <div class="metric-value" title={kpi.total.toLocaleString()}>
                    <div class="metric-value-total">
                      <span>{abbreviateNumber(kpi.total.toFixed(), 1)}</span>

                      {#if kpi.change !== 0}
                        <span class="change" class:negative={kpi.change < 0}>
                          <span>{kpi.change.toFixed()}%</span>
                          <i
                            class:links-icon-trending-down={kpi.change < 0}
                            class:links-icon-trending-up={kpi.change > 0}
                          ></i>
                        </span>
                      {/if}
                    </div>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        {/if}

        <div class="views-charts">
          <div class="views">
            <h2>{$format('label.VIEW_ENGAGEMENT')}</h2>
            {#if !noViewHistory}
              <Charts type="bar" bind:data={viewsConfig} bind:options={viewsOptions} />
            {:else}
              <div class="charts empty">
                <i class="links-icon-bar-chart-2" />
                <div>{$format('label.NO_DATA_AVAILABLE')}</div>
              </div>
            {/if}
          </div>
          <div class="sources">
            <h2 class="text-center">{$format('label.SOURCES')}</h2>
            {#if !noViewSource}
              <Charts type="doughnut" bind:data={chartData} />
            {:else}
              <div class="charts empty">
                <i class="links-icon-bar-chart-2" />
                <div>{$format('label.NO_DATA_AVAILABLE')}</div>
              </div>
            {/if}
          </div>
        </div>

        <div class="chart-grid">
          <Chart
            bind:data={time.data}
            bind:loading={time.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="time"
            title={$format('label.TIME_SAVED')}
          />
          {#if !links.length}
            <Chart
              bind:data={toplinks.data}
              bind:loading={toplinks.loading}
              bind:breakout={links.length}
              bind:colorMap
              type="links"
              title={$format('label.TOP_X', { values: { title: selectedField?.label } })}
            />
          {/if}
          <Chart
            bind:data={topusers.data}
            bind:loading={topusers.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="users"
            title={$format('label.TOP_USERS')}
          />
          {#if !links.length}
            <Chart
              bind:data={tags.data}
              bind:loading={tags.loading}
              bind:breakout={links.length}
              bind:colorMap
              type="tags"
              title={$format('label.TOP_TAGS')}
            />
          {/if}
          <Chart
            bind:data={browsers.data}
            bind:loading={browsers.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="browsers"
            title={$format('label.TOP_BROWSERS')}
          />
          <Chart
            bind:data={os.data}
            bind:loading={os.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="os"
            title={$format('label.OPERATING_SYSTEMS')}
          />
          <Chart
            bind:data={devices.data}
            bind:loading={devices.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="devices"
            title={$format('label.TOP_DEVICES')}
          />
          <Chart
            bind:data={countries.data}
            bind:loading={countries.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="countries"
            title={$format('label.TOP_COUNTRIES')}
          />
          <Chart
            bind:data={cities.data}
            bind:loading={cities.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="cities"
            title={$format('label.TOP_CITIES')}
          />
          <Chart
            bind:data={regions.data}
            bind:loading={regions.loading}
            bind:breakout={links.length}
            bind:colorMap
            type="regions"
            title={$format('label.TOP_REGIONS')}
          />
          <Chart bind:data={paths.data} bind:loading={paths.loading} type="paths" title={$format('label.TOP_PATHS')} />
          <Chart
            bind:data={referrers.data}
            bind:loading={referrers.loading}
            type="referrers"
            title={$format('label.TOP_REFERRERS')}
          />
          <Chart
            bind:data={broken.data}
            bind:loading={broken.loading}
            type="broken"
            title={$format('label.TOP_BROKEN_URLS')}
          />
        </div>
      </div>
      <div class="right-column">
        <section>
          <h2>{$format('label.TOP_USERS')} <small class="time-period">All Time</small></h2>
          {#if users.length}
            <ul class="top-users">
              {#each users as user}
                <li>
                  <a href="links?users={user.user}" use:link>
                    <img src={user.imageUrl} width="48" alt={user} referrerpolicy="no-referrer" />
                    <div>
                      <div class="name">{user.name}</div>
                      <div class="total small">{user.total} {$format('label.LINKS')}</div>
                    </div>
                  </a>
                </li>
              {/each}
            </ul>
          {:else}
            <div class="empty">
              <i class="links-icon-users" />
              <div>{$format('label.NO_TOP_USERS')}</div>
            </div>
          {/if}
        </section>
        <section>
          <h2>
            {$format('label.TRENDING')}
            <small class="time-period">{$format('label.LAST_X_DAYS', { values: { total: 90 } })}</small>
          </h2>
          {#if trending.data.length}
            <ul class="top-links">
              {#each trending.data as link}
                <li>
                  <a href="http://co/{link._id}" target="_blank">
                    <i class="links-icon-trending-up" />
                    <div>
                      <div class="name">{link._id}</div>
                      <div class="total small">
                        {$format('label.X_VIEWS_TOTAL', { values: { count: link.total } })}
                      </div>
                    </div>
                  </a>
                </li>
              {/each}
            </ul>
          {:else}
            <div class="empty">
              <i class="links-icon-trending-up" />
              <div>{$format('label.NO_TRENDING_LINKS')}</div>
            </div>
          {/if}
        </section>
        <section>
          <h2>
            {$format('label.POPULAR')}
            <small class="time-period">{$format('label.LAST_X_DAYS', { values: { total: 90 } })}</small>
          </h2>
          {#if popular.data.length}
            <ul class="top-links">
              {#each popular.data as link}
                <li>
                  <a href="http://co/{link._id}" target="_blank">
                    <i class="links-icon-star" />
                    <div>
                      <div class="name">{link._id}</div>
                      <div class="total small">
                        {$format('label.X_VIEWS_TOTAL', { values: { count: link.total } })}
                      </div>
                    </div>
                  </a>
                </li>
              {/each}
            </ul>
          {:else}
            <div class="empty">
              <i class="links-icon-star" />
              <div>{$format('label.NO_POPULAR_LINKS')}</div>
            </div>
          {/if}
        </section>
      </div>
    </div>
  </div>
</div>

<style lang="scss" src="./insights.scss"></style>
