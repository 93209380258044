<script>
  import { onMount, getContext } from 'svelte';
  import { format } from 'svelte-i18n';
  import { Tooltip } from 'sveltestrap';

  import { Button } from '@client/components/button';
  import { CodeMirror } from '@client/components/editor';
  import { getNotificationsContext } from '@client/components/notifications';
  import { Input } from '@client/components/input';
  import { Porthole } from '@client/components/porthole';
  import { Select } from '@client/components/select';
  import { Switch } from '@client/components/switch';

  import { LinksService } from '@client/services/links';
  import { TagsService } from '@client/services/tags';
  import { UsersService } from '@client/services/users';

  import { detailedDiff } from '@common/utils/objects';
  import { calculateTimeSaved } from '@client/utils/text';

  const { addNotification } = getNotificationsContext();

  const { prefix } = getContext('config');

  export let show = false;
  export let type = null;
  export let editing = false;
  export let uniqueLinkName = false;
  export let currentUser = null;
  export let onSave = function () {};
  export let onClose = function () {};
  export let link = {
    title: '',
    link: '',
    private: false,
    source: '',
    tags: [],
    editors: [],
    type: type || 'redirect'
  };

  let tags = [];
  let editors = [];
  let linkTypes = [
    { value: 'redirect', label: $format('label.SHORTCUT') },
    { value: 'snippet', label: $format('label.SNIPPET') },
    { value: 'template', label: $format('label.TEMPLATE') }
  ];

  let submitButton;
  let linkSourceIsValid = false;
  let timer = null;
  let minDate = new Date().toISOString().split('T')[0];

  let originalLink = null;
  let originalObject = null;

  const debounce = (fn, delay = 100) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  };

  const isLinkUnique = async () => {
    link.link = link.link.toLowerCase().replace(' ', '-');
    const response = await LinksService.get(link.link);
    uniqueLinkName = response === true || originalLink === link.link;
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitButton.click();
    }
  };

  const onCancel = () => {
    onClose();
    show = false;
  };

  const onUpdate = async () => {
    const { user } = currentUser;

    if (!editing) {
      link.link = link.link.toLowerCase();
      link.createdBy = currentUser;
    }

    link.updatedBy = user;

    const diff = detailedDiff(originalObject, link);

    if (link.type === 'snippet' || link.type === 'template') {
      link.timeSaved = calculateTimeSaved(link.source);
    }

    if (editing && originalLink !== link.link) {
      await LinksService.delete({ link: originalLink, user: link.createdBy.user, tags: link.tags });
      await LinksService.create({ ...link, diff });
    } else {
      const action = editing ? 'update' : 'create';
      await LinksService[action]({ ...link, diff });

      const label = editing ? 'label.NOTIFICATION.LINK_UPDATE_SUCCESS' : 'label.NOTIFICATION.LINK_CREATE_SUCCESS';

      const text = $format(label, {
        values: {
          item: `${prefix}/${link.link}`
        }
      });

      addNotification({
        text,
        type: 'success',
        autohide: 3000,
        position: 'top-right'
      });
    }

    onSave(true);

    originalLink = null;
    originalObject = null;
    linkSourceIsValid = false;

    onCancel();
  };

  let selectedItems = [];
  let selectedEditors = [];

  const getUsers = async () => {
    const { records } = await UsersService.getAll();

    const items = records.map(({ name, user, ...rest }) => ({
      label: name,
      value: user,
      ...rest
    }));

    editors = items.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    editors = editors.filter(({ value }) => value !== currentUser.user);
  };

  const getTags = async () => {
    const { records } = await TagsService.getAll();

    const items = records.map(({ name, user, ...rest }) => ({
      label: name,
      value: user,
      ...rest
    }));

    tags = items.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    tags = tags.filter(({ value }) => value !== currentUser.user);
  };

  onMount(async () => {
    await getUsers();
    await getTags();
  });

  $: if (show) {
    if (editing) {
      linkSourceIsValid = true;

      if (link?.tags?.length) {
        selectedItems = link.tags.map((label) => label);
      }

      if (link?.editors?.length) {
        selectedEditors = link.editors.map((user) => user);
      }
    }
  }

  $: if (link.link.length && originalLink === null) {
    originalLink = link.link;
    originalObject = JSON.parse(JSON.stringify(link));
  }

  $: if (!show) {
    link = {
      title: '',
      link: '',
      private: false,
      source: '',
      tags: [],
      editors: [],
      type: type || 'redirect'
    };

    originalLink = null;
    selectedItems = [];
  }

  const onTagClear = ({ detail }) => {
    const index = link.tags.indexOf(detail.value);
    link.tags.splice(index, 1);
    link.tags = link.tags;
  };

  const onEditorClear = ({ detail }) => {
    const index = link.editors.indexOf(detail.value);
    link.editors.splice(index, 1);
    link.editors = link.editors;
  };

  const onEditorSelection = ({ detail }) => {
    link.editors = detail.map(({ value }) => value);
  };

  const onTagSelection = ({ detail }) => {
    link.tags = detail.map(({ value }) => value);
  };

  const onTypeChange = ({ detail }) => {
    link.type = detail.value;
  };

  const formatTimeSaved = (text) => {
    const seconds = calculateTimeSaved(text);

    if (seconds < 60) {
      return $format('label.MESSAGE.TIME_SAVED_SECONDS', { values: { duration: seconds.toFixed(0), type: link.type } });
    }

    if (seconds < 3600) {
      const minutes = (seconds / 60).toFixed(0);
      return $format('label.MESSAGE.TIME_SAVED_MINUTES', { values: { duration: minutes, type: link.type } });
    }

    const hours = (seconds / 3600).toFixed(0);
    return $format('label.MESSAGE.TIME_SAVED_HOURS', { values: { duration: hours, type: link.type } });
  };

  let hasExpiration = link.expiry;
  let expirationDate = link.expiry ? link.expiry.split('T')[0] : '';

  $: if (hasExpiration) {
    const localDate = new Date(expirationDate);

    link.expiry = expirationDate?.length ? localDate.getTime() + localDate.getTimezoneOffset() * 60000 : '';
    link = { ...link };
  }

  $: if (!hasExpiration) {
    link.expiry = null;
    link = { ...link };
  }

  $: items = (tags || []).map(({ label, ...rest }) => ({ ...rest, label, value: label }));
  $: isFormValid =
    link.title.length &&
    link.source.length &&
    link.link.length &&
    ((link.type === 'template' && link.source.length) ||
      (link.type === 'snippet' && link.source.length) ||
      (link.type === 'redirect' && linkSourceIsValid)) &&
    (!hasExpiration || (hasExpiration && expirationDate && !isNaN(new Date(expirationDate).getTime()))) &&
    uniqueLinkName;
</script>

<div class="create-edit-link">
  <div class="layout" class:expanded={link.type !== 'redirect'}>
    <div class="editor">
      <div class="field">
        {#if link.type !== 'redirect'}
          <label for="source">
            {#if link.type === 'template'}
              {$format('label.TEMPLATE')}
            {:else}
              {$format('label.SNIPPET')}
            {/if}
          </label>
          <div class="editor-container">
            <CodeMirror bind:value={link.source} />
          </div>
          {#if link?.source?.length}
            <div class="time-saved">{@html formatTimeSaved(link.source)}</div>
          {/if}
        {/if}
      </div>
    </div>
    <div class="form">
      <div class="field">
        <label for="title">
          {$format('label.TITLE')}
          <i class="links-icon-help-circle" id="tooltip-link-title" />
          <Tooltip target="tooltip-link-title" placement="top">
            {$format('label.TOOLTIP.LINK_TITLE')}
          </Tooltip>
        </label>
        <Input autofocus type="text" id="title" class="fill" bind:value={link.title} on:keyup={onKeyUp} />
      </div>
      <div class="field">
        <label for="types">
          {$format('label.TYPE')}
          <i class="links-icon-help-circle" id="tooltip-link-type" />
          <Tooltip target="tooltip-link-type" placement="top">
            {@html $format('label.TOOLTIP.LINK_TYPES')}
          </Tooltip>
        </label>
        <Select
          items={linkTypes}
          value={link.type}
          placeholder="Select the type of link"
          class="select-element"
          searchable
          showChevron
          clearable={false}
          on:change={onTypeChange}
        >
          <svelte:fragment slot="item" let:item>
            <div class="item">
              <div class="item-detail">
                <div>{item.label}</div>
              </div>
            </div>
          </svelte:fragment>
        </Select>
      </div>
      <div class="field" class:template={link.type !== 'redirect'}>
        {#if link.type !== 'redirect'}
          <label for="source">
            {#if link.type === 'template'}
              {$format('label.TEMPLATE')}
            {:else}
              {$format('label.SNIPPET')}
            {/if}
          </label>
          <textarea id="source" bind:value={link.source} rows="5" />
        {:else}
          <label for="source">
            URL
            <i class="links-icon-help-circle" id="tooltip-link-url" />
            <Tooltip target="tooltip-link-url" placement="top">
              {$format('label.TOOLTIP.LINK_SOURCE')}
            </Tooltip>
          </label>
          <Input
            type="text"
            id="source"
            class="fill"
            bind:value={link.source}
            on:keyup={onKeyUp}
            bind:valid={linkSourceIsValid}
            pattern="^(http|file|\w+|https)://(.*)"
          />
        {/if}
      </div>
      <div class="field">
        <label for="link">
          {$format('label.LINK')}
          <i class="links-icon-help-circle" id="tooltip-link-name" />
          <Tooltip target="tooltip-link-name" placement="top">
            {@html $format('label.TOOLTIP.LINK_NAME', {
              values: {
                type: linkTypes.find(({ value }) => value === link.type)?.label.toLowerCase()
              }
            })}
          </Tooltip>
        </label>
        <div class="link-input" class:disabled={editing}>
          <div class="link-input-prepend">{prefix}/</div>
          <Input
            type="text"
            id="link"
            class="fill"
            bind:value={link.link}
            on:keyup={(e) => debounce(isLinkUnique) && onKeyUp(e)}
          />
          {#if link.link?.length >= 2}
            <div class="indicator">
              {#if uniqueLinkName || (editing && link.link === originalLink)}
                <i class="links-icon-check" />
              {:else}
                <i class="links-icon-block" />
              {/if}
            </div>
          {/if}
        </div>
      </div>
      {#if link.type === 'redirect'}
        <div class="field">
          <label for="expires">
            {$format('label.EXPIRES')}
            <i class="links-icon-help-circle" id="tooltip-link-expires" />
            <Tooltip target="tooltip-link-expires" placement="top">
              {$format('label.TOOLTIP.LINK_EXPIRATION')}
            </Tooltip>
          </label>
          <Switch bind:checked={hasExpiration} />
          <br />
          {#if hasExpiration}
            <Input
              type="date"
              id="expires"
              class="fill"
              format="yyyy-MM-dd"
              min={minDate}
              bind:value={expirationDate}
              on:keyup={onKeyUp}
            />
          {/if}
        </div>
      {/if}
      <div class="field">
        <label for="tags">
          {$format('label.TAGS')}
          <i class="links-icon-help-circle" id="tooltip-link-tags" />
          <Tooltip target="tooltip-link-tags" placement="top">
            {$format('label.TOOLTIP.LINK_TAGS')}
          </Tooltip>
        </label>
        <Select
          bind:items
          bind:value={selectedItems}
          placeholder={$format('label.PLACEHOLDER_SELECT_TAGS')}
          class="select-element"
          searchable
          multiple
          on:clear={onTagClear}
          on:change={onTagSelection}
        >
          <svelte:fragment slot="item" let:item>
            <div class="item">
              <div class="item-detail">
                <span style="background: {item.bg}; color: {item.color};">{item.label}</span>
              </div>
            </div>
          </svelte:fragment>
        </Select>
      </div>
      <div class="field">
        <label for="tags">
          {$format('label.EDITORS')}
          <i class="links-icon-help-circle" id="tooltip-link-editors" />
          <Tooltip target="tooltip-link-editors" placement="top">
            {$format('label.TOOLTIP.LINK_EDITORS')}
          </Tooltip>
        </label>
        <Select
          bind:items={editors}
          bind:value={selectedEditors}
          placeholder={$format('label.PLACEHOLDER_ADDITIONAL_EDITORS')}
          class="select-element"
          searchable
          multiple
          on:clear={onEditorClear}
          on:change={onEditorSelection}
        >
          <svelte:fragment slot="item" let:item>
            <div class="item">
              <Porthole details={item} color={item.color} />
              <div class="item-detail">
                <div class="name">{item.label}</div>
                <div class="email">{item.email}</div>
              </div>
            </div>
          </svelte:fragment>
        </Select>
      </div>
      <div class="field">
        <label for="link">
          {$format('label.PRIVATE')}
          <i class="links-icon-help-circle" id="tooltip-link-private" />
          <Tooltip target="tooltip-link-private" placement="top">
            {$format('label.TOOLTIP.LINK_PRIVATE')}
          </Tooltip>
        </label>
        <Switch bind:checked={link.private} />
        {#if link.private}
          <small>{$format('label.MESSAGE.LINK_NOT_PUBLICLY_LISTED')}</small>
        {/if}
      </div>
    </div>
  </div>
  <div class="actions">
    <Button className="mr-md" bind:ref={submitButton} on:click={onUpdate} disabled={!isFormValid}>
      {#if !editing}
        {$format('label.SAVE')}
      {:else}
        {$format('label.UPDATE')}
      {/if}
    </Button>
    <Button kind="link" on:click={onCancel}>{$format('label.CANCEL')}</Button>
  </div>
</div>

<style lang="scss" src="./edit.scss"></style>
