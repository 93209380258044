<script>
  import { getContext } from 'svelte';
  import { format } from 'svelte-i18n';
  import { useQuery } from '@sveltestack/svelte-query/svelte';

  import { ActivityModal, CreateEditModal, ConfirmModal } from '@client/components/manager/modals';
  import { Button } from '@client/components/button';
  import { GettingStarted } from '@client/components/wizard';
  import { Grid } from '@client/components/grid';
  import { Loader } from '@client/components/loader';
  import { MultiSelectFilter } from '@client/components/filters/multiselect';
  import { Pagination } from '@client/components/pagination';
  import { Porthole } from '@client/components/porthole';
  import { SearchFilter } from '@client/components/filters/search';
  import { getNotificationsContext } from '@client/components/notifications';

  import { ActionsCell, ActivityCell, LinkCell, OwnerCell, TitleCell, VisibilityCell } from './cell-formatters';

  import { BookmarksService } from '@client/services/bookmarks';
  import { LinksService } from '@client/services/links';
  import { TagsService } from '@client/services/tags';
  import { UsersService } from '@client/services/users';
  import { ViewsService } from '@client/services/views';

  import { formatTimestamp } from '@client/utils/dates';
  import { QueryParams } from '@client/utils/query-params';

  import { userStore } from '@client/stores/user';
  import { linksSettingsStore } from '@client/stores/page-settings';

  const { addNotification } = getNotificationsContext();

  const { prefix } = getContext('config');

  export let type = 'redirect';
  export let favorites = false;

  const getParamValue = (property, defaultValue, formatter) => {
    const value = QueryParams.get(property) || defaultValue;
    // const value = QueryParams.get(property) || $linksSettingsStore.params[property] || defaultValue;

    if (formatter) {
      return formatter(value);
    }

    return value;
  };

  let queryParams = {
    field: getParamValue('field', 'viewCount'),
    limit: getParamValue('perpage', 25, Number),
    order: getParamValue('order', -1),
    page: getParamValue('page', 0, (page) => Math.max(0, page - 1)),
    search: getParamValue('search', ''),
    users: getParamValue('users', ''),
    tags: getParamValue('tags', '')
  };

  let filters = {
    users: $linksSettingsStore.filters.users || [],
    tags: $linksSettingsStore.filters.tags || []
  };

  let context = getParamValue('context', 'links');
  let currentUser = {};
  let links = [];
  let isEditing = false;
  let uniqueLinkName = false;
  let removeLink = null;
  let showReportModal = false;
  let showConfirmModal = false;
  let showCreateEdit = false;
  let showZeroState = false;
  let totalRecords = 0;
  let totalPages = 0;
  let initialized = false;
  let tags = [];
  let users = [];

  let linkConfig = {
    title: '',
    link: '',
    private: false,
    source: '',
    type: type || 'redirect',
    tags: [],
    editors: []
  };

  const remove = ({ link }) => {
    removeLink = link;
    showConfirmModal = true;
  };

  const confirmRemoval = async () => {
    const linkUser = links.find(({ link }) => link === removeLink);

    await LinksService.delete({
      link: removeLink,
      user: linkUser.createdBy.user,
      tags: linkUser.tags
    });

    queryFn();

    const text = $format('label.LINK_REMOVED_SUCCESS', {
      values: {
        link: `${prefix}/${removeLink}`
      }
    });

    addNotification({
      text,
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });

    removeLink = null;
    showConfirmModal = false;
  };

  const create = () => {
    showCreateEdit = true;
    isEditing = false;
  };

  const edit = async (link) => {
    const response = await LinksService.get(link.link);

    if (response) {
      linkConfig = { ...response };
      showCreateEdit = true;
      isEditing = true;
      uniqueLinkName = true;
    }
  };

  const report = ({ link }) => {
    showReportModal = link;
  };

  const visibility = async (link) => {
    link.private = !link.private;
    await LinksService.update(link);
    links = [...links];

    const text = $format('label.LINK_VISIBILITY_CHANGE', {
      values: {
        link: `${prefix}/${link.link}`,
        state: link.private ? $format('label.PRIVATE') : $format('label.PUBLIC')
      }
    });

    addNotification({
      text,
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });
  };

  const reset = () => {
    uniqueLinkName = false;
    showConfirmModal = false;
    showReportModal = false;
    showCreateEdit = false;
    isEditing = false;
    linkConfig = {
      title: '',
      source: '',
      link: '',
      tags: [],
      editors: [],
      type: type || 'redirect'
    };
  };

  const onColumnSort = (field, order) => {
    $linksSettingsStore.params = { ...queryParams, field, order };
  };

  const addBookmark = async (link) => {
    currentUser.bookmarks.add(link);

    await BookmarksService.create({
      user: currentUser.user,
      link
    });

    currentUser = { ...currentUser };

    links = [...links];
  };

  const removeBookmark = async (link) => {
    currentUser.bookmarks.delete(link);

    await BookmarksService.delete({
      user: currentUser.user,
      link
    });

    currentUser = { ...currentUser };

    links = [...links];
  };

  // const changeSource = async (which) => {
  //   context = which;

  //   if (context === 'my-links') {
  //     queryParams = { ...queryParams, context, users: currentUser.user, page: 0 };
  //   }

  //   if (context === 'links') {
  //     queryParams = { ...queryParams, context, users: '', page: 0 };
  //   }

  //   if (context === 'bookmarks') {
  //     queryParams = { ...queryParams, context, users: '', page: 0 };
  //   }

  //   QueryParams.set('context', context);
  //   QueryParams.set('page', 1);
  // };

  const onLinkClick = (link) => ViewsService.record(link, currentUser.user);

  let columns = [
    {
      id: 'link',
      sortKey: 'link',
      label: $format('label.LINK'),
      renderComponent: {
        component: LinkCell,
        props: {
          onLinkClick,
          addBookmark,
          removeBookmark,
          currentUser: $userStore,
          prefix
        }
      }
    },
    {
      id: 'title',
      sortKey: 'title',
      label: $format('label.DETAILS'),
      renderComponent: {
        component: TitleCell,
        props: {
          tags
        }
      }
    },
    {
      id: 'activity',
      label: '',
      sortable: false,
      renderComponent: ActivityCell
    },
    {
      id: 'viewCount',
      class: 'text-align--center',
      sortKey: 'viewCount',
      label: type === 'redirect' ? $format('label.VIEWS') : $format('label.USED'),
      renderValue: ({ viewCount }) => viewCount
    },
    {
      id: 'private',
      sortKey: 'private',
      label: $format('label.PUBLIC'),
      align: 'center',
      renderComponent: VisibilityCell
    },
    {
      id: 'createdBy',
      sortKey: 'createdBy.user',
      label: $format('label.USER'),
      renderComponent: OwnerCell
    },
    // {
    //   id: 'createdAt',
    //   sortKey: 'createdAt',
    //   label: $format('label.CREATED'),
    //   renderValue: ({ createdAt }) => formatTimestamp(createdAt, 'MM/dd/yyyy')
    // },
    {
      id: 'updatedAt',
      sortKey: 'updatedAt',
      label: $format('label.UPDATED'),
      renderValue: ({ updatedAt }) => formatTimestamp(updatedAt, 'MM/dd/yyyy @ hh:mm a')
    },
    {
      id: 'actions',
      label: '',
      renderComponent: {
        component: ActionsCell,
        props: {
          edit,
          remove,
          report,
          visibility,
          currentUser: $userStore
        }
      }
    }
  ];

  const onChangePage = (page) => {
    $linksSettingsStore.params = { ...queryParams, page };
  };

  const onChangeRecords = (limit) => {
    $linksSettingsStore.params = { ...queryParams, limit };
  };

  const updateQueryParams = () => {
    queryParams = { ...queryParams };
    $linksSettingsStore.params = queryParams;
  };

  const createUsersFilter = async () => {
    const { records } = await UsersService.getAll();

    const items = records.map(({ name, user, ...rest }) => ({
      label: name,
      value: user,
      ...rest
    }));

    users = items.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    filters.users = users.slice();

    users = users.filter(({ value }) => value !== currentUser.user);

    $linksSettingsStore.filters = filters;
  };

  const createTagsFilter = () => {
    const items = tags.map(({ label, ...rest }) => ({
      label,
      value: label,
      ...rest
    }));

    tags = items.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    filters.tags = tags.slice();

    $linksSettingsStore.filters = filters;
  };

  const queryFn = async () => {
    const { directory, pagination, records } =
      context !== 'bookmarks' && !favorites
        ? await LinksService.getAll({ ...queryParams, type })
        : await BookmarksService.getAll({ ...queryParams });

    const getTags = await TagsService.getAll();
    tags = getTags.records;
    columns[1].renderComponent.props.tags = tags.reduce((acc, value) => (acc[value.label] = value) && acc, {});

    createTagsFilter();

    totalRecords = pagination.total;
    totalPages = pagination.pages;
    links = records;

    showZeroState =
      !links.length && !(filters.users.length && queryParams.search.length) && context === 'links' && directory === 0;

    initialized = true;

    return links;
  };

  createUsersFilter();

  const query = useQuery([context, queryParams], queryFn, { keepPreviousData: true });

  $: if (!showCreateEdit) {
    linkConfig = {
      title: '',
      source: '',
      link: '',
      tags: [],
      editors: [],
      type: type || 'redirect'
    };
  }

  $: updateQueryParams(queryParams);
  $: query.updateOptions({ queryKey: [context, queryParams], queryFn });
  $: currentUser = { ...$userStore };
</script>

<div class="page with-footer links">
  <Loader backdrop show={$query.isLoading || $query.isFetching} />

  {#if initialized}
    {#if !showZeroState}
      <div class="page-header">
        <div class="row">
          <div class="col-md-12">
            <h1>
              {#if favorites}
                {$format('label.MANAGE_FAVORITES')}
              {:else if type === 'redirect'}
                {$format('label.MANAGE_SHORTCUTS')}
              {:else if type === 'template'}
                {$format('label.MANAGE_TEMPLATES')}
              {:else if type === 'snippet'}
                {$format('label.MANAGE_SNIPPETS')}
              {/if}
            </h1>
            {#if !favorites}
              <Button on:click={create}>
                {#if type === 'redirect'}
                  {$format('label.NEW_SHORTCUT')}
                {:else if type === 'template'}
                  {$format('label.NEW_TEMPLATE')}
                {:else if type === 'snippet'}
                  {$format('label.NEW_SNIPPET')}
                {/if}
              </Button>
            {/if}
          </div>
        </div>
      </div>
    {/if}
    <div class="page-content">
      {#if showZeroState}
        <GettingStarted />
      {:else}
        <div class="top">
          <section class="filters">
            <div class="filters-left">
              <SearchFilter bind:search={queryParams.search} />
              {#if context !== 'my-links' && filters.users.length > 1}
                <MultiSelectFilter
                  bind:items={filters.users}
                  bind:selectedValues={queryParams.users}
                  disabled={context === 'my-links'}
                  label={$format('label.USERS')}
                  menuHeight="300px"
                  param="users"
                  search
                  tooltipLabel={$format('label.SELECTED_OWNERS')}
                >
                  <svelte:fragment slot="label" let:item>
                    <div class="item">
                      <Porthole details={item} color={item.color} size="small" />
                      <div class="item-detail">
                        <div class="name">{item.label}</div>
                        <div class="email">{item.email}</div>
                      </div>
                    </div>
                  </svelte:fragment>
                </MultiSelectFilter>
              {/if}

              {#if filters.tags.length > 1}
                <MultiSelectFilter
                  bind:items={filters.tags}
                  bind:selectedValues={queryParams.tags}
                  label={$format('label.TAGS')}
                  menuHeight="300px"
                  param="tags"
                  search
                  tooltipLabel={$format('label.SELECTED_TAGS')}
                >
                  <svelte:fragment slot="label" let:item>
                    <div class="item">
                      <div class="item-detail">
                        <span style="background: {item.bg}; color: {item.color};">{item.label}</span>
                      </div>
                    </div>
                  </svelte:fragment>
                </MultiSelectFilter>
              {/if}
            </div>
          </section>
        </div>

        <Grid
          className="links-grid"
          bind:columns
          bind:rows={links}
          bind:sortKey={queryParams.field}
          bind:sortOrder={queryParams.order}
          onSort={onColumnSort}
        />
      {/if}
    </div>
    <div class="page-footer">
      {#if links.length}
        <Pagination
          bind:currentPage={queryParams.page}
          bind:perPage={queryParams.limit}
          bind:totalRecords
          bind:totalPages
          {onChangeRecords}
          {onChangePage}
        />
      {/if}
    </div>
  {/if}
</div>

{#if showCreateEdit}
  <CreateEditModal
    bind:show={showCreateEdit}
    bind:link={linkConfig}
    bind:editing={isEditing}
    bind:uniqueLinkName
    bind:editors={users}
    {tags}
    {type}
    onSave={queryFn}
    onClose={reset}
    {currentUser}
  />
{/if}

<ConfirmModal bind:show={showConfirmModal} bind:link={removeLink} onSave={confirmRemoval} onClose={reset} />

<ActivityModal bind:show={showReportModal} link={showReportModal} onClose={reset} />

<style lang="scss" src="./links.scss"></style>
