<script>
  import { onMount, setContext } from 'svelte';
  import { Router, Route } from 'svelte-navigator';
  import { QueryClientProvider, QueryClient } from '@sveltestack/svelte-query/svelte';

  import { base, themes } from './themes';
  import { getBootstrap, processLoggedInUser } from '@client/app/bootstrap';

  import { CreateLinkModal } from '@client/components/manager/modals';
  import { Loader } from '@client/components/loader';
  import { Notification } from '@client/components/notification';
  import { Notifications } from '@client/components/notifications';
  import { Sidebar, sidebarStore } from '@client/components/sidebar';

  import { Theme } from '@client/components/theme';

  import { appStore } from '@client/stores/app';
  import { modalsStore } from '@client/stores/modals';
  import { userStore } from '@client/stores/user';

  import { Login } from '@client/pages/login';
  import { Insights } from '@client/pages/insights';
  import { Integrations } from '@client/pages/integrations';
  import { Invites } from '@client/pages/invites';
  import { Links } from '@client/pages/links';
  import { Privacy } from '@client/pages/privacy';
  import { Settings } from '@client/pages/settings';
  import { Tags } from '@client/pages/tags';
  import { Team } from '@client/pages/team';
  import { Terms } from '@client/pages/terms';

  import { UsersService } from '@client/services/users';

  import { debounce } from '@common/utils/debounce';
  import { LocalStorage } from '@client/utils/local-storage';

  import Redirect from './redirect.svelte';

  const queryClient = new QueryClient();

  const config = getBootstrap();
  const { theme } = config;

  setContext('config', config);

  let mobile = window.innerWidth < 600;
  let sidebarCollapsed = mobile ? true : LocalStorage.get('sidebar')?.collapsed;

  if (sidebarCollapsed === undefined) {
    LocalStorage.create('sidebar', { collapsed: false });
    sidebarCollapsed = false;
  }

  let loading = true;
  let initialized = false;
  let authenticated = false;
  let collapsed = sidebarCollapsed;
  let interacting = !sidebarCollapsed;
  let pathname = window.location.pathname.slice(1);
  let defaultPathname = '/';
  let extensionInstalled = true;

  const toggleSidebar = () => {
    collapsed = !collapsed;
    interacting = !collapsed;

    LocalStorage.update('sidebar', collapsed, 'collapsed');

    sidebarStore.set({
      collapsed,
      interacting
    });
  };

  const onToggleClick = () => {
    if (collapsed && !interacting) {
      interacting = true;
      collapsed = false;
    } else {
      interacting = false;
      collapsed = true;
    }
  };

  const initialize = async () => {
    const user = await UsersService.get();

    initialized = true;
    authenticated = !user.error;

    if (authenticated) {
      $userStore = processLoggedInUser(user);
    }

    setTimeout(() => {
      extensionInstalled = 'ontouchstart' in window ? true : document.body.dataset.colinks;
    }, 500);
  };

  const onResize = debounce(() => {
    mobile = window.innerWidth < 600;
  }, 150);

  let displayMode = '';

  window.addEventListener('DOMContentLoaded', () => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    }

    if (mobile && displayMode !== 'standalone') {
      const root = document.documentElement;
      root.style.setProperty('--app-height', window.innerHeight + 'px');
    }
  });

  onMount(initialize);

  $: sidebarMenuIcon = collapsed && !interacting ? 'links-icon-menu' : 'links-icon-x';
  $: loading = $appStore?.loading;
  $: authenticated = $appStore?.authenticated;
  $: defaultPathname = authenticated ? '/links' : '/';
  $: sidebarToggleIcon = !collapsed && interacting ? 'links-icon-chevron-left' : 'links-icon-chevron-right';
  $: isMobile = window.innerWidth < 600;
  $: $appStore.isMobile = isMobile;
  $: showExtensionNotification = !isMobile && initialized && authenticated && !extensionInstalled;
  $: if (isMobile) {
    collapsed = true;
    interacting = false;
  }
</script>

<Theme {base} {themes} {theme}>
  <QueryClientProvider client={queryClient}>
    <Notifications>
      {#if showExtensionNotification}
        <Notification>
          <span>You'll need to install the Chrome extension to access your co/links.</span>
          <a
            href="https://chrome.google.com/webstore/detail/colinks/pciabmbjggmolgclddfmcfbipibfeapp?hl=en&authuser=0"
            target="_blank"
            class="btn btn-secondary">Install Now</a
          >
        </Notification>
      {/if}
      <div id="layout">
        <Loader show={loading} />
        <Router primary={false}>
          {#if initialized}
            {#if authenticated}
              <Sidebar bind:collapsed bind:interacting bind:mobile />
              <button
                id="sidebar-toggle"
                class="hide--mobile"
                on:click={toggleSidebar}
                class:collapsed={collapsed && !interacting}
              >
                <i class={sidebarToggleIcon} />
              </button>
              <button id="sidebar-toggle-mobile" on:click={onToggleClick} class:collapsed={collapsed && !interacting}>
                <i class={sidebarMenuIcon} />
              </button>
            {/if}
            <div id="content">
              {#if authenticated}
                <Route path="links">
                  <Links type="redirect" />
                </Route>
                <Route path="snippets">
                  <Links type="snippet" />
                </Route>
                <Route path="templates">
                  <Links type="template" />
                </Route>
                <Route path="favorites">
                  <Links favorites />
                </Route>
                <Route path="insights">
                  <Insights />
                </Route>
                <Route path="tags">
                  <Tags />
                </Route>
                <Route path="team">
                  <Team />
                </Route>
                <Route path="invites">
                  <Invites />
                </Route>
                <Route path="integrations">
                  <Integrations />
                </Route>
                <Route path="settings">
                  <Settings />
                </Route>
              {:else}
                <Route path="privacy">
                  <Privacy />
                </Route>
                <Route path="terms">
                  <Terms />
                </Route>
                <Route path="/" component={Login} />
              {/if}

              <Route path="/redirect">
                <Redirect path={pathname} />
              </Route>

              <Route path="/*">
                <Redirect path={defaultPathname} />
              </Route>

              {#if $modalsStore.showCreateLink}
                <CreateLinkModal bind:show={$modalsStore.showCreateLink} type="redirect" currentUser={$userStore} />
              {/if}
            </div>
          {/if}
        </Router>
      </div>
    </Notifications>
  </QueryClientProvider>
</Theme>

<svelte:window on:resize={onResize} />

<style lang="scss" src="./app.scss"></style>
