<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';
  import { ColorPicker } from '@client/components/color-picker';

  import { getNotificationsContext } from '@client/components/notifications';
  import { Input } from '@client/components/input';
  import { Modal } from '@client/components/modal';

  import { TagsService } from '@client/services/tags';

  import { detailedDiff } from '@common/utils/objects';

  const { addNotification } = getNotificationsContext();

  export let show = false;
  export let editing = false;
  export let uniqueTagLabel = false;
  export let onSave = function () {};
  export let onClose = function () {};
  export let tag = {
    label: '',
    description: '',
    bg: '#b8c9ff',
    color: '#585da7',
    links: []
  };

  let maxWidth = '400px';
  let formLabelInput;
  let submitButton;
  let tagLabelIsValid = false;
  let timer = null;

  let originalTagLabel = null;
  let originalTagObject = null;

  const debounce = (fn, delay = 100) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  };

  const isTagUnique = async () => {
    tag.label = tag.label.toLowerCase().replace(' ', '-');
    const response = await TagsService.get(tag.label);
    uniqueTagLabel = response === true || originalTagLabel === tag.label;
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitButton.click();
    }
  };

  const onUpdate = async () => {
    if (!editing) {
      tag.label = tag.label.toLowerCase();
    }

    if (editing && originalTagLabel !== tag.label) {
      await TagsService.delete({ label: originalTagLabel, links: tag.links });
      await TagsService.create(tag);
    } else {
      const diff = detailedDiff(originalTagObject, tag);
      const action = editing ? 'update' : 'create';
      await TagsService[action]({ ...tag, diff });

      const label = editing ? 'label.NOTIFICATION.TAG_UPDATE_SUCCESS' : 'label.NOTIFICATION.TAG_CREATE_SUCCESS';

      const text = $format(label, {
        values: {
          item: tag.label
        }
      });

      addNotification({
        text,
        type: 'success',
        autohide: 3000,
        position: 'top-right'
      });
    }

    onSave(true);

    originalTagLabel = null;
    originalTagObject = null;
    tagLabelIsValid = false;

    onClose();
  };

  $: if (show) {
    if (editing) {
      tagLabelIsValid = true;
    }
  }

  $: if (tag.label.length && originalTagLabel === null) {
    originalTagLabel = tag.label;
    originalTagObject = JSON.parse(JSON.stringify(tag));
  }

  $: if (!show) {
    tag = {
      label: '',
      description: '',
      bg: '#b8c9ff',
      color: '#585da7',
      links: []
    };

    originalTagLabel = null;
  }

  $: isFormValid = tag.label.length && tag.bg.length && tag.color.length && uniqueTagLabel;
</script>

<Modal bind:maxWidth bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>
        {#if editing}
          {$format('label.EDITING_TAG', { values: { tag: tag.label } })}
        {:else if tag.label}
          {$format('label.CREATING_TAG', { values: { tag: tag.label } })}
        {:else}
          {$format('label.CREATE_NEW_TAG')}
        {/if}
      </h1>
    </div>
    <div class="modals--content" on:keyup={onKeyUp} role="button" tabindex="0">
      <div class="field">
        <label for="tag-label">{$format('label.LABEL')}</label>
        <div class="tag-label-input" class:disabled={editing}>
          <Input
            bind:ref={formLabelInput}
            type="text"
            id="tag-label"
            class="fill"
            bind:value={tag.label}
            on:keyup={(e) => debounce(isTagUnique) && onKeyUp(e)}
            autofocus
          />
          {#if tag.label?.length >= 2}
            <div class="indicator">
              {#if uniqueTagLabel || (editing && tag.label === originalTagLabel)}
                <i class="links-icon-check" />
              {:else}
                <i class="links-icon-block" />
              {/if}
            </div>
          {/if}
        </div>
      </div>
      <div class="field">
        <label for="bg">{$format('label.BACKGROUND')}</label>
        <ColorPicker id="bg" bind:color={tag.bg} />
      </div>
      <div class="field">
        <label for="color">{$format('label.COLOR')}</label>
        <ColorPicker id="color" bind:color={tag.color} />
      </div>
      <div class="field">
        <label for="description">{$format('label.DESCRIPTION')}</label>
        <textarea id="description" bind:value={tag.description} rows="5" />
      </div>
      {#if tag.label.length}
        <div class="field">
          <label for="color">{$format('label.PREVIEW')}</label>
          <di>
            <div class="tag-preview" style="background-color: {tag.bg}; color: {tag.color};">
              {tag.label}
            </div>
          </di>
        </div>
      {/if}
    </div>
    <div class="modals--footer">
      <Button className="mr-md" bind:ref={submitButton} on:click={onUpdate} disabled={!isFormValid}>
        {#if !editing}
          {$format('label.SAVE')}
        {:else}
          {$format('label.UPDATE')}
        {/if}
      </Button>
      <Button kind="link" on:click={onClose}>{$format('label.CANCEL')}</Button>
    </div>
  </div>
</Modal>

<style lang="scss" src="./edit.scss"></style>
