import { externalTooltipHandler } from './tooltip';

import merge from 'lodash/merge';

const updateTooltipShow = (chart, enabled) => {
  chart.options.tooltips.enabled = enabled;
  chart.update();
};

const configs = {
  defaults: {
    responsive: true,
    maintainAspectRatio: false,
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'],
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
        events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend']
      }
    },
    tooltips: {
      enabled: false
    },
    onHover: function (e) {
      const { bottom, top, right, left } = this.chartArea;
      updateTooltipShow(this, e.x >= left && e.x <= right && e.y <= bottom && e.y >= top);
    }
  },
  bubble: {},
  bar: {},
  doughnut: {},
  pie: {},
  scatter: {
    interaction: {
      axis: 'xy',
      mode: 'index',
      intersect: false
    },
    elements: {
      line: {
        borderColor: 'rgb(0, 135, 255)',
        borderWidth: 2
      },
      point: {
        radius: 0
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    }
  },
  line: {
    plugins: {
      title: {
        display: true
      },
      legend: {
        display: false,
        align: 'end',
        position: 'bottom'
      }
    },
    interaction: {
      axis: 'xy',
      mode: 'index',
      intersect: false
    },
    tooltips: {
      enabled: false
    },
    elements: {
      line: {
        borderColor: 'rgb(0, 135, 255)',
        borderWidth: 2
      },
      point: {
        radius: 0
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [0],
          borderDashOffset: [0],
          color: 'rgba(0,0,0,.075)',
          zeroLineColor: 'rgba(0,0,0,.1)'
        },
        ticks: {
          font: {
            size: 12
          }
        }
      },
      y: {
        grid: {
          display: true,
          borderDash: [2],
          borderDashOffset: [0],
          drawBorder: false,
          color: 'rgba(0,0,0,.075)',
          zeroLineColor: 'rgba(0,0,0,.1)'
        }
      }
    }
  }
};

export const generateChartsConfig = (type, options = {}) => {
  return merge({}, configs.defaults, configs[type], options);
};
