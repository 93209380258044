const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.className = 'chart-tooltip';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0 || !chart.options.tooltips.enabled) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const contents = tooltipEl;
  const helpers = chart.config.options?.helpers;
  const sortByValues = chart.config.options?.sortByValues;
  const hideTooltipZeros = chart.config.options?.hideTooltipZeros;
  const content = document.createElement('div');

  while (contents.firstChild) {
    contents.firstChild.remove();
  }

  if (tooltip.body) {
    const title = tooltip.title || [];
    let lines = tooltip.body
      .map((b) => b.lines)
      .flat()
      .sort((a, b) => (sortByValues ? b.split(': ')[1] - a.split(': ')[1] : b.localeCompare(a)));

    if (hideTooltipZeros) {
      lines = lines.filter((line) => line.split(': ')[1] !== '0');

      if (!lines.length) {
        tooltipEl.style.opacity = 0;
        return;
      }
    }

    if (title.length) {
      let header = document.createElement('div');

      if (helpers?.tooltipHeaderFormatter) {
        const result = helpers.tooltipHeaderFormatter(title);

        if (result) {
          header = result;
        }
      } else {
        header.className = 'header';

        title.forEach((title) => {
          const div = document.createElement('div');
          const text = document.createTextNode(title);

          div.appendChild(text);
          header.appendChild(div);
        });
      }

      contents.appendChild(header);
    }

    if (helpers?.tooltipContentFormatter) {
      if (!helpers.tooltipContentFormatter(content, lines, tooltip)) {
        content.innerhHTML = '';
        tooltipEl.style.opacity = 0;
        return;
      }
    } else {
      lines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
        const point = document.createElement('div');

        point.className = 'point';
        point.style.background =
          typeof colors.backgroundColor === 'string' ? colors.backgroundColor : colors.borderColor;

        if (colors.borderColor) {
          point.style.border = `1px solid ${colors.borderColor}`;
        }

        const item = document.createElement('div');
        item.className = 'item';

        const metric = document.createElement('div');
        const value = document.createElement('div');
        const [m, v] = body.split(': ');

        metric.className = 'metric';

        if (helpers?.tooltipMetricFormatter) {
          metric.innerHTML = helpers.tooltipMetricFormatter(m);
        } else {
          metric.innerHTML = m;
        }

        value.className = 'value';

        if (helpers?.tooltipValueFormatter) {
          value.innerHTML = helpers.tooltipValueFormatter(v);
        } else {
          value.innerHTML = v;
        }

        const pair = document.createElement('div');

        pair.className = 'pair';
        pair.appendChild(metric);
        pair.appendChild(value);

        item.appendChild(point);
        item.appendChild(pair);

        content.appendChild(item);
      });
    }

    contents.appendChild(content);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;

  const rect = tooltipEl.getBoundingClientRect();
  const x = tooltip.x || tooltip._eventPosition.x;
  const y = tooltip.y || tooltip._eventPosition.y;
  const containerWidth = parseInt(chart.canvas.style.width);

  tooltipEl.style.left = x + positionX + 24 + 'px';
  tooltipEl.style.top = y + positionY + 24 + 'px';

  if (containerWidth < x + rect.width) {
    tooltipEl.style.left = x - (positionX + 48) + 'px';
  }
};
