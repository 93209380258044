<script>
  import { Loader } from '@client/components/loader';
  import { useQuery } from '@sveltestack/svelte-query/svelte';
  import { format } from 'svelte-i18n';

  import { UserCell } from './cell-formatters';

  import { Grid } from '@client/components/grid';
  import { Pagination } from '@client/components/pagination';
  import { SearchFilter } from '@client/components/filters/search';

  import { UsersService } from '@client/services/users';

  import { QueryParams } from '@client/utils/query-params';

  import { usersSettingsStore } from '@client/stores/page-settings';

  let users = [];
  let totalRecords = 0;
  let totalPages = 0;

  const getParamValue = (property, defaultValue, formatter) => {
    const value = QueryParams.get(property) || $usersSettingsStore.params[property] || defaultValue;

    if (formatter) {
      return formatter(value);
    }

    return value;
  };

  let queryParams = {
    field: getParamValue('field', 'name'),
    limit: getParamValue('perpage', 25, Number),
    order: getParamValue('order', -1),
    page: getParamValue('page', 0, (page) => Math.max(0, page - 1)),
    search: getParamValue('search', '')
  };

  const onChangePage = (page) => {
    $usersSettingsStore.params = { ...queryParams, page };
  };

  const onChangeRecords = (limit) => {
    $usersSettingsStore.params = { ...queryParams, limit };
  };

  const updateQueryParams = () => {
    queryParams = { ...queryParams };
    $usersSettingsStore.params = queryParams;
  };

  let columns = [
    {
      id: 'name',
      sortKey: 'name',
      label: $format('label.TEAM_MEMBER'),
      renderComponent: UserCell
    },
    {
      id: 'email',
      sortKey: 'email',
      label: $format('label.EMAIL'),
      renderValue: ({ email }) => email
    },
    {
      id: 'role',
      sortKey: 'role',
      label: $format('label.ROLE'),
      renderValue: ({ role }) => {
        const type = role || 'user';

        return type.charAt(0).toUpperCase() + type.slice(1);
      }
    },
    {
      id: 'links',
      sortable: false,
      label: $format('label.LINKS'),
      renderValue: ({ links }) => links.length
    }
  ];

  const onColumnSort = (field, order) => {
    $usersSettingsStore.params = { ...queryParams, field, order };
  };

  const queryFn = async () => {
    const { records, pagination } = await UsersService.getAll(queryParams);

    users = records;
    totalRecords = pagination.total;
    totalPages = pagination.pages;
    users = [...users];

    return users;
  };

  const query = useQuery(['teams', queryParams], queryFn, { keepPreviousData: true });

  $: updateQueryParams(queryParams);
  $: query.updateOptions({ queryKey: ['teams', queryParams], queryFn });
  $: users = $query.data || [];
</script>

<div class="page team with-footer">
  <Loader backdrop show={$query.isLoading || $query.isFetching} />
  <div class="page-header">
    <div class="row">
      <div class="col-md-6">
        <h1>{$format('label.MANAGE_TEAM')}</h1>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="top">
      <section class="filters">
        <div class="filters-left">
          <SearchFilter bind:search={queryParams.search} />
        </div>
      </section>
    </div>
    <Grid
      className="team-grid"
      bind:columns
      bind:rows={users}
      bind:sortKey={queryParams.field}
      bind:sortOrder={queryParams.order}
      onSort={onColumnSort}
    />
  </div>

  <div class="page-footer">
    {#if users?.length}
      <Pagination
        bind:currentPage={queryParams.page}
        bind:perPage={queryParams.limit}
        bind:totalRecords
        bind:totalPages
        {onChangeRecords}
        {onChangePage}
      />
    {/if}
  </div>
</div>

<style lang="scss" src="./team.scss"></style>
