<script>
  import { getContext } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';
  import { CodeMirror } from '@client/components/editor';
  import { getNotificationsContext } from '@client/components/notifications';
  import { Input } from '@client/components/input';
  import { Modal } from '@client/components/modal';
  import { Porthole } from '@client/components/porthole';
  import { Select } from '@client/components/select';
  import { Switch } from '@client/components/switch';

  import { LinksService } from '@client/services/links';

  import { appStore } from '@client/stores/app';

  import { detailedDiff } from '@common/utils/objects';
  import { calculateTimeSaved } from '@client/utils/text';

  const { addNotification } = getNotificationsContext();

  const { prefix } = getContext('config');

  export let show = false;
  export let type = null;
  export let editing = false;
  export let uniqueLinkName = false;
  export let currentUser = null;
  export let tags = [];
  export let editors = [];
  export let onSave = function () {};
  export let onClose = function () {};
  export let link = {
    title: '',
    link: '',
    private: false,
    source: '',
    tags: [],
    editors: [],
    type: type || 'redirect'
  };

  let maxWidth = $appStore.isMobile ? '380px' : type === 'redirect' ? '400px' : '800px';
  let formTitleInput;
  let submitButton;
  let linkSourceIsValid = false;
  let timer = null;

  let originalLink = null;
  let originalObject = null;

  const debounce = (fn, delay = 100) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  };

  const isLinkUnique = async () => {
    link.link = link.link.toLowerCase().replace(' ', '-');
    const response = await LinksService.get(link.link);
    uniqueLinkName = response === true || originalLink === link.link;
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitButton.click();
    }
  };

  const onCancel = () => {
    onClose();
    show = false;
  };

  const onUpdate = async () => {
    const { user } = currentUser;

    if (!editing) {
      link.link = link.link.toLowerCase();
      link.createdBy = currentUser;
    }

    link.updatedBy = user;

    const diff = detailedDiff(originalObject, link);

    if (editing && originalLink !== link.link) {
      await LinksService.delete({ link: originalLink, user: link.createdBy.user, tags: link.tags });
      await LinksService.create({ ...link, diff });
    } else {
      const action = editing ? 'update' : 'create';
      await LinksService[action]({ ...link, diff });

      const text = $format('label.LINK_MODIFIED_SUCCESS', {
        values: {
          link: `${prefix}/${link.link}`,
          action: editing ? $format('label.UPDATED') : $format('label.CREATED')
        }
      });

      addNotification({
        text,
        type: 'success',
        autohide: 3000,
        position: 'top-right'
      });
    }

    onSave(true);

    originalLink = null;
    originalObject = null;
    linkSourceIsValid = false;

    onCancel();
  };

  let items = (tags || []).map(({ label, ...rest }) => ({ ...rest, label, value: label }));
  let editorItems = editors;
  let selectedItems = [];
  let selectedEditors = [];

  $: if (show) {
    if (editing) {
      linkSourceIsValid = true;

      if (link?.tags?.length) {
        selectedItems = link.tags.map((label) => label);
      }

      if (link?.editors?.length) {
        selectedEditors = link.editors.map((user) => user);
      }
    }
  }

  $: if (link.link.length && originalLink === null) {
    originalLink = link.link;
    originalObject = JSON.parse(JSON.stringify(link));
  }

  $: if (!show) {
    link = {
      title: '',
      link: '',
      private: false,
      source: '',
      tags: [],
      editors: [],
      type: type || 'redirect'
    };

    originalLink = null;
    selectedItems = [];
  } else {
    //setTimeout(() => formTitleInput.focus(), 100);
  }

  const onTagClear = ({ detail }) => {
    const index = link.tags.indexOf(detail.value);
    link.tags.splice(index, 1);
    link.tags = link.tags;
  };

  const onEditorClear = ({ detail }) => {
    const index = link.editors.indexOf(detail.value);
    link.editors.splice(index, 1);
    link.editors = link.editors;
  };

  const onEditorSelection = ({ detail }) => {
    link.editors = detail.map(({ value }) => value);
  };

  const onTagSelection = ({ detail }) => {
    link.tags = detail.map(({ value }) => value);
  };

  const onTypeChange = ({ detail }) => {
    link.type = detail.value;
  };

  const formatTimeSaved = (text) => {
    const seconds = calculateTimeSaved(text);

    if (seconds < 60) {
      return `You save <b>${seconds.toFixed(0)} ${seconds > 1 ? 'seconds' : 'second'}</b> each time you use this ${link.type}.`;
    }

    if (seconds < 3600) {
      const minutes = (seconds / 60).toFixed(0);
      return `You save <b>${minutes} ${minutes > 1 ? 'minutes' : 'minute'}</b> each time you use this ${link.type}.`;
    }

    const hours = (seconds / 3600).toFixed(0);
    return `You save <b>${hours} ${hours > 1 ? 'hours' : 'hour'}</b> each time you use this ${link.type}.`;
  };

  $: maxWidth = $appStore.isMobile ? '380px' : link.type === 'redirect' ? '400px' : '800px';
  $: items = (tags || []).map(({ label, ...rest }) => ({ ...rest, label, value: label }));
  $: isFormValid =
    link.title.length &&
    link.source.length &&
    link.link.length &&
    ((link.type === 'template' && link.source.length) ||
      (link.type === 'snippet' && link.source.length) ||
      (link.type === 'redirect' && linkSourceIsValid)) &&
    uniqueLinkName;
</script>

<Modal bind:maxWidth bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>
        {#if editing}
          {$format('label.EDITING_LINK', { values: { link: `${prefix}/${link.link}` } })}
        {:else if link.link}
          {$format('label.CREATING_LINK', { values: { link: `${prefix}/${link.link}` } })}
        {:else if link.type === 'template'}
          {$format('label.NEW_TEMPLATE')}
        {:else if link.type === 'snippet'}
          {$format('label.NEW_SNIPPET')}
        {:else}
          {$format('label.NEW_SHORTCUT')}
        {/if}
      </h1>
    </div>
    <div class="modals--content">
      <div class="layout" class:expanded={link.type !== 'redirect'}>
        <div class="editor">
          <div class="field">
            {#if link.type !== 'redirect'}
              <label for="source">
                {#if link.type === 'template'}
                  {$format('label.TEMPLATE')}
                {:else}
                  {$format('label.SNIPPET')}
                {/if}
              </label>
              <div class="editor-container">
                <CodeMirror bind:value={link.source} />
              </div>
              {#if link?.source?.length}
                <div class="time-saved">{@html formatTimeSaved(link.source)}</div>
              {/if}
            {/if}
          </div>
        </div>
        <div class="form">
          <div class="field">
            <label for="title">{$format('label.TITLE')}</label>
            <Input
              bind:ref={formTitleInput}
              autofocus
              type="text"
              id="title"
              class="fill"
              bind:value={link.title}
              on:keyup={onKeyUp}
            />
          </div>
          <div class="field">
            <label for="tags">{$format('label.TYPE')}</label>
            <Select
              items={[
                { value: 'redirect', label: 'Redirect' },
                { value: 'snippet', label: 'Snippet' },
                { value: 'template', label: 'Template' }
              ]}
              bind:value={link.type}
              placeholder="Select the type of link"
              class="select-element"
              searchable
              showChevron
              clearable={false}
              on:change={onTypeChange}
            >
              <svelte:fragment slot="item" let:item>
                <div class="item">
                  <div class="item-detail">
                    <div>{item.label}</div>
                  </div>
                </div>
              </svelte:fragment>
            </Select>
          </div>
          <div class="field" class:template={link.type !== 'redirect'}>
            {#if link.type !== 'redirect'}
              <label for="source">
                {#if link.type === 'template'}
                  {$format('label.TEMPLATE')}
                {:else}
                  {$format('label.SNIPPET')}
                {/if}
              </label>
              <textarea id="source" bind:value={link.source} rows="5" />
            {:else}
              <label for="source">URL</label>
              <Input
                type="text"
                id="source"
                class="fill"
                bind:value={link.source}
                on:keyup={onKeyUp}
                bind:valid={linkSourceIsValid}
                pattern="^(http|https)://(.*)"
              />
            {/if}
          </div>
          <div class="field">
            <label for="link">{$format('label.LINK')}</label>
            <div class="link-input" class:disabled={editing}>
              <div class="link-input-prepend">{prefix}/</div>
              <Input
                type="text"
                id="link"
                class="fill"
                bind:value={link.link}
                on:keyup={(e) => debounce(isLinkUnique) && onKeyUp(e)}
              />
              {#if link.link?.length >= 2}
                <div class="indicator">
                  {#if uniqueLinkName || (editing && link.link === originalLink)}
                    <i class="links-icon-check" />
                  {:else}
                    <i class="links-icon-block" />
                  {/if}
                </div>
              {/if}
            </div>
          </div>
          <div class="field">
            <label for="tags">{$format('label.TAGS')}</label>
            <Select
              bind:items
              bind:value={selectedItems}
              placeholder={$format('label.PLACEHOLDER_SELECT_TAGS')}
              class="select-element"
              searchable
              multiple
              on:clear={onTagClear}
              on:change={onTagSelection}
            >
              <svelte:fragment slot="item" let:item>
                <div class="item">
                  <div class="item-detail">
                    <span style="background: {item.bg}; color: {item.color};">{item.label}</span>
                  </div>
                </div>
              </svelte:fragment>
            </Select>
          </div>
          <div class="field">
            <label for="tags">{$format('label.EDITORS')}</label>
            <Select
              bind:items={editorItems}
              bind:value={selectedEditors}
              placeholder={$format('label.PLACEHOLDER_ADDITIONAL_EDITORS')}
              class="select-element"
              searchable
              multiple
              on:clear={onEditorClear}
              on:change={onEditorSelection}
            >
              <svelte:fragment slot="item" let:item>
                <div class="item">
                  <Porthole details={item} color={item.color} />
                  <div class="item-detail">
                    <div class="name">{item.label}</div>
                    <div class="email">{item.email}</div>
                  </div>
                </div>
              </svelte:fragment>
            </Select>
          </div>
          <div class="field">
            <label for="link">{$format('label.PRIVATE')}</label>
            <Switch bind:checked={link.private} />
            {#if link.private}
              <small>{$format('label.NOT_PUBLICLY_LISTED')}</small>
            {/if}
          </div>
        </div>
      </div>
      <div class="modals--footer">
        <Button className="mr-md" bind:ref={submitButton} on:click={onUpdate} disabled={!isFormValid}>
          {#if !editing}
            {$format('label.SAVE')}
          {:else}
            {$format('label.UPDATE')}
          {/if}
        </Button>
        <Button kind="link" on:click={onCancel}>{$format('label.CANCEL')}</Button>
      </div>
    </div>
  </div>
</Modal>

<style lang="scss" src="./edit.scss"></style>
