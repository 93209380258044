<script>
  import { relativeTimestamp } from '@client/utils/dates';

  export let row = {};
  export let tags;

  let domain = '';

  const extractDomain = (url) => {
    try {
      let parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (e) {
      console.error('Invalid URL:', e);
      return null;
    }
  };

  const updateSrc = function () {
    this.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  };

  $: row.type === 'redirect' && (domain = extractDomain(row.source));
</script>

<div>{row.title}</div>

<div class="source">
  {#if row.type === 'redirect'}
    <div class="img">
      <img
        src="https://logo.clearbit.com/{domain}"
        on:load={updateSrc}
        referrerpolicy="no-referrer"
        alt={domain}
        style="background: url(https://logo.clearbit.com/{domain}) no-repeat center center / contain;"
      />
    </div>
    <a href={row.source} target="_blank">{row.source}</a>
  {:else}
    <div title={row.source} style="white-space: break-spaces;">{row.source}</div>
  {/if}
</div>

{#if row.lastViewed}
  <small>Last visited {relativeTimestamp(row.lastViewed)}</small>
{/if}

{#if row.tags}
  <div class="tags">
    {#each row.tags as tag}
      <span class="tag" style="background-color: {tags[tag].bg}; color: {tags[tag].color}">{tags[tag].label}</span>
    {/each}
  </div>
{/if}

<style lang="scss">
  .source {
    align-items: center;
    display: flex;
    font-size: var(--font-size-medium);
    gap: 8px;
    margin: 6px 0;
    word-break: break-all;

    .img {
      border-radius: 100%;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
      margin: 0;
      min-width: 20px;
      height: 20px;
      overflow: hidden;
      width: 20px;

      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }

    + .tags {
      margin-top: 8px;
    }
  }

  small {
    color: var(--color-gray-600);
    display: block;
  }

  .tags {
    display: flex;
    gap: 8px;
    margin: 6px 0;
  }

  .tag {
    background-color: var(--color-gray-400);
    border-radius: var(--border-radius-base);
    color: var(--color-gray-800);
    font-size: var(--font-size-small);
    padding: 4px 8px;
  }
</style>
