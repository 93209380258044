<script>
  import { format } from 'svelte-i18n';
  import { Tooltip } from 'sveltestrap';

  import { Button } from '@client/components/button';
  import { Input } from '@client/components/input';
  import { Modal } from '@client/components/modal';
  import { Porthole } from '@client/components/porthole';
  import { Select } from '@client/components/select';
  import { Switch } from '@client/components/switch';
  import { getNotificationsContext } from '@client/components/notifications';

  import { appStore } from '@client/stores/app';

  import { UrlsService } from '@client/services/urls';

  import { detailedDiff } from '@common/utils/objects';

  const { addNotification } = getNotificationsContext();

  export let show = false;
  export let editing = false;
  export let currentUser = null;
  export let tags = [];
  export let editors = [];
  export let onSave = function () {};
  export let onClose = function () {};
  export let urlConfig = {
    domainHash: '',
    originalLink: '',
    originalUrl: '',
    title: '',
    tags: [],
    editors: []
  };

  let maxWidth = $appStore.isMobile ? '100%' : '480px';
  let submitButton;
  let minDate = new Date().toISOString().split('T')[0];
  let originalUrlIsValid = false;
  let originalUrl = null;
  let originalObject = null;

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitButton.click();
    }
  };

  const onCancel = () => {
    onClose();

    urlConfig = {
      domainHash: '',
      originalLink: '',
      originalUrl: '',
      title: '',
      tags: [],
      editors: []
    };

    show = false;
  };

  const onUpdate = async () => {
    const { user } = currentUser;

    if (!editing) {
      urlConfig.createdBy = currentUser;
    }

    urlConfig.updatedBy = user;

    const diff = detailedDiff(originalObject, urlConfig);
    const action = editing ? 'update' : 'create';
    await UrlsService[action]({ ...urlConfig, diff });

    const label = editing ? 'label.NOTIFICATION.URL_UPDATE_SUCCESS' : 'label.NOTIFICATION.URL_CREATE_SUCCESS';

    const text = $format(label, {
      values: {
        item: urlConfig.title
      }
    });

    addNotification({
      text,
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });

    onSave(true);

    originalUrl = null;
    originalObject = null;
    originalUrlIsValid = false;

    onCancel();
  };

  let items = (tags || []).map(({ label, ...rest }) => ({ ...rest, label, value: label }));
  let editorItems = editors;
  let selectedItems = [];
  let selectedEditors = [];

  $: if (show) {
    if (editing) {
      originalUrlIsValid = true;

      if (urlConfig?.tags?.length) {
        selectedItems = urlConfig.tags.map((label) => label);
      }

      if (urlConfig?.editors?.length) {
        selectedEditors = urlConfig.editors.map((user) => user);
      }
    }
  }

  $: if (urlConfig.originalUrl.length && originalUrl === null) {
    originalUrl = urlConfig.originalUrl;
    originalObject = JSON.parse(JSON.stringify(urlConfig));
  }

  $: if (!show) {
    urlConfig = {
      originalLink: '',
      originalUrl: '',
      title: '',
      tags: [],
      editors: []
    };

    originalUrl = null;
    selectedItems = [];
  }

  const onTagClear = ({ detail }) => {
    const index = urlConfig.tags.indexOf(detail.value);
    urlConfig.tags.splice(index, 1);
    urlConfig.tags = urlConfig.tags;
  };

  const onEditorClear = ({ detail }) => {
    const index = urlConfig.editors.indexOf(detail.value);
    urlConfig.editors.splice(index, 1);
    urlConfig.editors = urlConfig.editors;
  };

  const onEditorSelection = ({ detail }) => {
    urlConfig.editors = detail.map(({ value }) => value);
  };

  const onTagSelection = ({ detail }) => {
    urlConfig.tags = detail.map(({ value }) => value);
  };

  let hasExpiration = urlConfig.expiry;
  let expirationDate = urlConfig.expiry ? urlConfig.expiry.split('T')[0] : '';

  $: if (hasExpiration) {
    const localDate = new Date(expirationDate);

    urlConfig.expiry = expirationDate?.length ? localDate.getTime() + localDate.getTimezoneOffset() * 60000 : '';
    urlConfig = { ...urlConfig };
  }

  $: if (!hasExpiration) {
    urlConfig.expiry = null;
    urlConfig = { ...urlConfig };
  }

  $: isFormValid =
    urlConfig.originalUrl.length &&
    urlConfig.title?.length &&
    JSON.stringify(originalObject) !== JSON.stringify(urlConfig) &&
    (!hasExpiration || (hasExpiration && expirationDate && !isNaN(new Date(expirationDate).getTime()))) &&
    originalUrlIsValid;
</script>

<Modal bind:maxWidth bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>
        {#if editing}
          {$format('label.EDIT_SHORT_URL')}
        {:else}
          {$format('label.CREATE_SHORT_URL')}
        {/if}
      </h1>
    </div>
    <div class="modals--content">
      <div class="form">
        <div class="field">
          <label for="title">
            {$format('label.TITLE')}
            <i class="links-icon-help-circle" id="tooltip-url-title" />
            <Tooltip target="tooltip-url-title" placement="top">
              {$format('label.TOOLTIP.URL_TITLE')}
            </Tooltip>
          </label>
          <Input autofocus type="text" id="title" class="fill" bind:value={urlConfig.title} on:keyup={onKeyUp} />
        </div>
        <div class="field">
          <label for="source">
            URL
            <i class="links-icon-help-circle" id="tooltip-url-url" />
            <Tooltip target="tooltip-url-url" placement="top">
              {$format('label.TOOLTIP.URL_SOURCE')}
            </Tooltip>
          </label>
          <Input
            type="text"
            id="source"
            class="fill"
            bind:value={urlConfig.originalUrl}
            on:keyup={onKeyUp}
            bind:valid={originalUrlIsValid}
            pattern="^(http|file|\w+|https)://(.*)"
          />
        </div>
        <div class="field">
          <label for="tags">
            {$format('label.TAGS')}
            <i class="links-icon-help-circle" id="tooltip-url-tags" />
            <Tooltip target="tooltip-url-tags" placement="top">
              {$format('label.TOOLTIP.URL_TAGS')}
            </Tooltip>
          </label>
          <Select
            bind:items
            bind:value={selectedItems}
            placeholder={$format('label.PLACEHOLDER_SELECT_TAGS')}
            class="select-element"
            searchable
            multiple
            on:clear={onTagClear}
            on:change={onTagSelection}
          >
            <svelte:fragment slot="item" let:item>
              <div class="item">
                <div class="item-detail">
                  <span style="background: {item.bg}; color: {item.color};">{item.label}</span>
                </div>
              </div>
            </svelte:fragment>
          </Select>
        </div>
        <div class="field">
          <label for="editors">
            {$format('label.EDITORS')}
            <i class="links-icon-help-circle" id="tooltip-url-editors" />
            <Tooltip target="tooltip-url-editors" placement="top">
              {$format('label.TOOLTIP.URL_EDITORS')}
            </Tooltip>
          </label>
          <Select
            bind:items={editorItems}
            bind:value={selectedEditors}
            placeholder={$format('label.PLACEHOLDER_ADDITIONAL_EDITORS')}
            class="select-element"
            searchable
            multiple
            on:clear={onEditorClear}
            on:change={onEditorSelection}
          >
            <svelte:fragment slot="item" let:item>
              <div class="item">
                <Porthole details={item} color={item.color} />
                <div class="item-detail">
                  <div class="name">{item.label}</div>
                  <div class="email">{item.email}</div>
                </div>
              </div>
            </svelte:fragment>
          </Select>
        </div>

        <div class="field">
          <label for="expires">
            {$format('label.EXPIRES')}
            <i class="links-icon-help-circle" id="tooltip-url-expires" />
            <Tooltip target="tooltip-url-expires" placement="top">
              {$format('label.TOOLTIP.URL_EXPIRATION')}
            </Tooltip>
          </label>
          <Switch bind:checked={hasExpiration} />
          <br />
          {#if hasExpiration}
            <Input
              type="date"
              id="expires"
              class="fill"
              format="yyyy-MM-dd"
              min={minDate}
              bind:value={expirationDate}
              on:keyup={onKeyUp}
            />
          {/if}
        </div>
      </div>
      <div class="modals--footer">
        <Button className="mr-md" bind:ref={submitButton} on:click={onUpdate} disabled={!isFormValid}>
          {#if !editing}
            {$format('label.SAVE')}
          {:else}
            {$format('label.UPDATE')}
          {/if}
        </Button>
        <Button kind="link" on:click={onCancel}>{$format('label.CANCEL')}</Button>
      </div>
    </div>
  </div>
</Modal>

<style lang="scss" src="./edit.scss"></style>
