<script>
  import { onMount } from 'svelte';
  import { UsersService } from '@client/services/users';

  import { processLoggedInUser } from '@client/app/bootstrap';

  import { appStore } from '@client/stores/app';
  import { userStore } from '@client/stores/user';

  let loaded = false;
  let error = false;
  let focus = false;
  let email = '';
  let showEmail = false;
  let selectedProvider = null;
  let providers = {};
  let hasProviderError = false;

  const redirectToOauthUrl = (provider) => {
    window.location.href = providers[provider].authUrl;
  };

  const redirectToOauthUrlWithParams = async () => {
    hasProviderError = false;

    const check = await fetchProviders(email);

    if (check.okta.domain) {
      window.location.href = `${providers[selectedProvider].authUrl}?email=${email}`;
      return;
    }

    hasProviderError = true;
  };

  const onProviderClick = (name) => {
    if (name === 'google') {
      redirectToOauthUrl(name);
      return;
    }

    if (name === 'okta') {
      selectedProvider = 'okta';
      showEmail = true;
    }
  };

  const resetSelections = () => {
    selectedProvider = null;
    showEmail = false;
    email = '';
    hasProviderError = false;
  };

  const fetchProviders = async (email) => {
    const url = '/api/auth/providers' + (email ? `?email=${email}` : '');

    try {
      const request = await fetch(url);
      const { response } = await request.json();
      providers = response;
      loaded = true;
    } catch (err) {
      console.error('Failed to load auth providers:', err);
      error = true;
    }

    return providers;
  };

  onMount(async () => {
    await fetchProviders();

    const user = await UsersService.get();

    if (!user.error) {
      $userStore = processLoggedInUser(user);
      $appStore.authenticated = true;
    } else {
      if (user.error.status === 403 && user.error.message.includes('Personal')) {
        error = true;
      }
    }

    loaded = true;
  });
</script>

<div class="page">
  <div class="login" class:hide={!loaded}>
    {#if !error}
      <div class="logo">
        <div class="beta">BETA</div>
        <img src="images/colinks.svg" height="48" alt="co/links logo" />
      </div>
      {#if Object.keys(providers).length}
        <div class="sign-in">
          <p>Sign in with</p>

          <div class="sso-providers">
            <button
              class="provider"
              on:click={() => onProviderClick('google')}
              class:fade={selectedProvider !== null && selectedProvider !== 'google'}
              class:active={selectedProvider !== null && selectedProvider === 'google'}
            >
              <img src="https://colinks.s3.amazonaws.com/icons/google.png" alt="Google SSO" />
              <span>Google</span>
            </button>
            <button
              class="provider"
              class:fade={selectedProvider !== null && selectedProvider !== 'okta'}
              class:active={selectedProvider !== null && selectedProvider === 'okta'}
              on:click={() => onProviderClick('okta')}
            >
              <img src="https://colinks.s3.amazonaws.com/icons/okta.png" alt="Okta SSO" />
              <span>Okta</span>
            </button>
          </div>
        </div>
      {/if}
      <div class="email-input-wrapper" class:show={showEmail}>
        <p>Please enter your email and click <b>Next</b> or <button on:click={resetSelections}>cancel</button>.</p>

        <div class="email-input" class:focus>
          <input
            type="text"
            id="email"
            bind:value={email}
            on:focus={() => (focus = true)}
            on:blur={() => (focus = false)}
            placeholder="user@email.com"
          />
          <button on:click={redirectToOauthUrlWithParams} disabled={!email.length}>Next</button>
        </div>
      </div>
      {#if hasProviderError}
        <p id="error">Your organization does not have Okta configured.</p>
      {/if}
    {:else}
      <img src="https://dysfunc.s3.amazonaws.com/no-chance.gif" alt="No chance!" />
      <p>Only authorized accounts can access co/links.</p>
    {/if}
  </div>
</div>

<div class="footer">
  <div class="left-side">
    <span>Copyright © 2024 Colinks, LLC</span>
  </div>
  <div class="right-side">
    <a href="/terms">Terms</a>
    <a href="/privacy">Privacy Policy</a>
  </div>
</div>

<style lang="scss" src="./login.scss"></style>
