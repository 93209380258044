<script>
  import { onMount, onDestroy } from 'svelte';
  import { format } from 'svelte-i18n';
  import { link, globalHistory } from 'svelte-navigator';

  import { sidebarStore } from './sidebar.store';
  import { userStore } from '@client/stores/user';
  import { modalsStore } from '@client/stores/modals';

  import { AuthService } from '@client/services/auth';

  import sidebarConf from './config.json';

  const sidebarConfig = sidebarConf;

  export let collapsed = false;
  export let interacting = true;
  export let mobile = false;
  export let maxWidth = 298;
  export let showBackdrop = true;
  export let disableDrag = false;

  let dragging = false;
  let backdropOpacity = 0;
  let handleRef = null;
  let movementThrow = 0;
  let parentRoute = null;
  let showUserMenu = false;
  let showUserPhoto = true;
  let timer = null;
  let translateX = 0;

  const sections = Object.keys(sidebarConfig).map((key) => sidebarConfig[key]);

  const setPageDetails = ({ pathname }) => {
    parentRoute = pathname.split('/')[1];
  };

  const toggleUserMenu = () => {
    showUserMenu = !showUserMenu;
  };

  const renderUserInitials = () => {
    showUserPhoto = false;
  };

  const onSignOut = async () => {
    await AuthService.logout();
    window.location.reload();
  };

  const onCreateLink = () => {
    $modalsStore.showCreateLink = true;
  };

  const sidebarOpen = () => {
    timer = setTimeout(() => {
      if (collapsed && !interacting) {
        interacting = true;
        clearTimeout(timer);
        timer = null;
        sidebarStore.set({ interacting });
      }
    }, 300);
  };

  const sidebarClose = () => {
    if (timer) {
      clearTimeout(timer);
    }
    if (mobile) {
      translateX = 0;
      backdropOpacity = 0;
      collapsed = true;
      interacting = false;
    } else {
      translateX = 298;
      backdropOpacity = 0;
    }
    if (collapsed && interacting) {
      interacting = false;
      sidebarStore.set({ interacting });
    }
  };

  const onTouchStart = (e) => {
    e.preventDefault();
    dragging = true;
    interacting = true;
  };

  const onTouchEnd = () => {
    if (canInteract) {
      dragging = false;
      if (movementThrow > 8) {
        open();
        return;
      }
      if (movementThrow < -8) {
        close();
        return;
      }
      if (translateX > maxWidth / 2) {
        open();
      } else {
        close();
      }
    }
  };

  const onTouchMove = (e) => {
    if (canInteract) {
      const touch = Array.from(e.touches)[0];
      if (touch) {
        move({
          x: Math.round(touch.clientX),
          movementX: 0
        });
      }
    }
  };

  const move = ({ x, movementX }) => {
    movementThrow = movementX;
    if (x <= maxWidth && x >= 0) {
      translateX = x;
      backdropOpacity = x / maxWidth;
    } else if (x > maxWidth) {
      open();
    } else {
      close();
    }
  };

  const open = () => {
    interacting = true;
    collapsed = false;
    translateX = maxWidth;
    if (mobile) {
      backdropOpacity = 1;
    }
  };

  const close = () => {
    if (mobile) {
      translateX = 0;
      backdropOpacity = 0;
      setTimeout(() => {
        collapsed = true;
      }, 60);
    } else {
      translateX = 298;
      backdropOpacity = 0;
    }
    interacting = false;
  };

  onMount(() => {
    setPageDetails(window.location);
    globalHistory.listen(({ location }) => setPageDetails(location));
    if (mobile) {
      handleRef.addEventListener('touchstart', onTouchStart);
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    }
  });

  onDestroy(() => {
    if (mobile) {
      handleRef.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    }
  });

  $: canInteract = !disableDrag && dragging;
  $: !interacting && collapsed && close();
  $: interacting && !collapsed && open();
  $: if (!mobile) {
    maxWidth = 298;
    translateX = 298;
    backdropOpacity = 0;
  }

  $: if (mobile) {
    maxWidth = 298;
    translateX = 0;
  }

  $: email = $userStore?.email.split('@').pop();
</script>

{#if mobile}
  <div
    bind:this={handleRef}
    id="handle"
    class="handle"
    style={`transform: translate3d(${translateX}px, 0, 0); ${dragging ? 'transition: none' : ''}`}
  >
    <slot name="handle" />
  </div>
  <slot name="backdrop">
    {#if showBackdrop}
      <div style="opacity: {backdropOpacity};" class="backdrop" class:hide={!backdropOpacity} on:click={close} />
    {/if}
  </slot>
{/if}

<aside
  id="sidebar"
  class:collapsed={!interacting && collapsed && !mobile}
  class:mobile
  on:mouseenter={sidebarOpen}
  on:mouseleave={sidebarClose}
  style={`transform: translate3d(${translateX - maxWidth}px, 0, 0); ${dragging ? 'transition: none' : ''}`}
>
  <div class="sidebar-container">
    <div class="sidebar-header">
      <img
        class:emblem={!interacting && collapsed}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        alt="co/links"
      />
      <button class="create" on:click={onCreateLink}><i class="links-icon-plus" /></button>
    </div>
    <div class="sidebar-contents">
      <div class="top">
        {#each sections as section}
          <nav class="section">
            {#if section.label}
              <div class="heading">{$format(section.label)}</div>
            {/if}
            <ul>
              {#each section.items as item}
                {#if !item.disabled}
                  <li class:active={parentRoute === item.path} class:disabled={item.disabled}>
                    {#if item.disabled}
                      <div>
                        <i class={item.icon} />
                        <span>{$format(item.label)}</span>
                      </div>
                    {:else}
                      <a href={item.path} use:link on:click={sidebarClose}>
                        <i class={item.icon} />
                        <span>{$format(item.label)}</span>
                      </a>
                    {/if}
                  </li>
                {/if}
              {/each}
            </ul>
          </nav>
        {/each}
      </div>
      <div class="bottom">
        <nav class="section">
          <ul>
            <li>
              <div>
                <i class="links-icon-bell" />
                <span>{$format('label.NOTIFICATIONS')}</span>
              </div>
            </li>
            <li>
              <a href="mailto:support@colinks.io" target="_blank">
                <i class="links-icon-life-buoy" />
                <span>{$format('label.SUPPORT')}</span>
              </a>
            </li>
          </ul>
        </nav>
        <div class="user" on:click={toggleUserMenu}>
          {#if showUserPhoto}
            <img
              src={$userStore?.imageUrl}
              on:error={renderUserInitials}
              alt={$userStore?.name}
              referrerpolicy="no-referrer"
            />
          {:else}
            <div class="initials">{$userStore?.initials}</div>
          {/if}
          <div class="user-details">
            <div class="name">{$userStore?.name}</div>
            <div class="company">
              {#if email === 'gmail.com'}
                {$userStore?.email.split('@').shift()}
              {:else}
                @{email}
              {/if}
            </div>
          </div>
          <i class="links-icon-log-out" tabindex="0" on:click={onSignOut} />
        </div>
      </div>
    </div>
  </div>
</aside>

<style lang="scss" src="./sidebar.scss"></style>
